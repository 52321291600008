import React from 'react';
import MyAppCard from './MyAppCard';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { MyAppsCardProps } from './types';
import Container from '../Container';
import Cookies from 'js-cookie';
import { useUsage } from '../../../../contexts/UsageContext';


const MyAppUpdates: React.FC = () => {
const apiKey = Cookies.get('apiKey')!;

  const {usageMetrics} = useUsage();

  const updates: MyAppsCardProps[] = usageMetrics? [
    {
      appName: 'My App',
      requestCount: usageMetrics.cycle_api_requests,
      apiKey: apiKey,
    },
    // Additional updates can be added here
  ] : []; // Return an empty array if userUsage is null

  // Use useBreakpointValue hook to determine if we are on a small screen
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  // Only calculate dummy cards for non-small screens
  const dummyCardCount = isSmallScreen ? 0 : 3 - updates.length % 3; // Adjust based on your layout/grid system

  return (
    <Container bgTransparent={true}>
      {updates.map((update, index) => (
        <MyAppCard key={index} {...update} />
      ))}
      {!isSmallScreen && Array.from({ length: dummyCardCount }).map((_, index) => (
        <Box
          key={`dummy-${index}`}
          width="100%" // Match the width of MyAppCard
          height="250px" // Match the height of MyAppCard
          visibility="hidden" // Make the card invisible
        />
      ))}
    </Container>
  );
};

export default MyAppUpdates;
