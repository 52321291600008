import React, { createContext, useContext, useState, useEffect, ReactNode, useMemo } from 'react';
import { ApiUserRoleResponse, getUserRole } from '../services/appService';
import Cookies from 'js-cookie';

// CurrentRole Context and Provider
interface CurrentRoleContextType {
    currentRole: ApiUserRoleResponse | null;
    loadingRole: boolean;
  }
  
  const CurrentRoleContext = createContext<CurrentRoleContextType | undefined>(undefined);
  
  const fetchCurrentRole = async (): Promise<ApiUserRoleResponse> => {
    const accessToken = Cookies.get('AccessToken');
    return await getUserRole(accessToken!);
  };
  
  export const CurrentRoleProvider = ({ children }: { children: ReactNode }) => {
    const [currentRole, setCurrentRole] = useState<ApiUserRoleResponse | null>(null);
    const [loadingRole, setLoading] = useState<boolean>(true);
  
    useEffect(() => {
      const fetchAndSetCurrentRole = async () => {
        try {
          const role = await fetchCurrentRole();
          setCurrentRole(role);
        } catch (error) {
          console.error('Failed to fetch current role:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchAndSetCurrentRole();
    }, []);
  
    const value = useMemo(() => ({ currentRole, loadingRole }), [currentRole, loadingRole]);
  
    return (
      <CurrentRoleContext.Provider value={value}>
        {children}
      </CurrentRoleContext.Provider>
    );
  };
  
  export const useCurrentRole = (): CurrentRoleContextType => {
    const context = useContext(CurrentRoleContext);
    if (context === undefined) {
      throw new Error('useCurrentRole must be used within a CurrentRoleProvider');
    }
    return context;
  };