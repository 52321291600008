import React from 'react';
import { Flex, VStack, Button, Text, Box } from '@chakra-ui/react';

interface StepLayoutProps {
  title: string;
  description: string;
  onNextStep: () => void;
  children: React.ReactNode;
}

export const StepLayout: React.FC<StepLayoutProps> = ({ title, description, onNextStep, children }) => {
  return (
    <Flex direction="column" height="100%" justify="space-between"> {/* Ensure full height and space distribution */}
      <VStack spacing="4" align="stretch">
        <Text fontSize="xl" fontWeight="bold" textAlign="left" color="white">
          {title}
        </Text>
        <Text fontSize="sm" textAlign="left" color="white">
          {description}
        </Text>
        {children}
      </VStack>
      <Box p="4" width="100%"> {/* Adjust padding as needed */}</Box>
      <Flex justify="flex-end" p="0"> {/* Adjust padding as needed */}
        <Button
          sx={{
            backgroundColor: 'var(--primary-light)', // For background color
            color: 'white', // Change text color accordingly
            _hover: {
              bg: 'var(--primary-medium)', // Change for hover state, adjust variable as needed
            },
          }}
          onClick={onNextStep}
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};
