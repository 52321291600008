// CopyBox.tsx
import React from 'react';
import { Box } from '@chakra-ui/react';
import CopyButton from './CopyButton';

interface CopyBoxProps {
    text: string;
}

const CopyBox: React.FC<CopyBoxProps> = ({ text }) => {
    //const { hasCopied, onCopy } = useClipboard(text);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={3}
            borderWidth="1px"
            borderRadius="lg"
            borderColor="white"
            position="relative"
            // Ensure the container fills its content to the edges
            maxW={{ base: '100%' }}
            height="100%"
        >
            <Box color="white" flex="1" mr={4}>{text}</Box>
            
                <CopyButton text={text}/>
            </Box>
   
    );
};

export default CopyBox;
