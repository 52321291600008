import React from 'react';
import { Box, SimpleGrid, Text, Image, LinkBox, LinkOverlay, VStack } from '@chakra-ui/react';

// Import assets
import quickStartImage from '../../assets/images/onboarding/Labs_Onboarding3_QuickstartA_Compressed.jpg';
import apiReferenceImage from '../../assets/images/onboarding/Labs_Onboarding3_API Reference_Overview_Compressed.jpg';
import tutorialsImage from '../../assets/images/onboarding/Labs_Onboarding3_API_Tutorials_Compressed.jpg';

const StartBuildingStep = () => {
  const items = [
    {
      title: 'Quick Start',
      subtext: 'The easiest way to start pulling data for your market.',
      imageUrl: quickStartImage,
      link: 'https://docs.parcllabs.com/docs/parcl-labs-developer-quickstart-guide',
    },
    {
      title: 'API Reference',
      subtext: "An overview of all the housing data API endpoints.",
      imageUrl: apiReferenceImage,
      link: 'https://docs.parcllabs.com/reference',
    },
    {
      title: 'Examples',
      subtext: 'Learn by building. Examples on how to recreate Parcl Labs analysis and charts.',
      imageUrl: tutorialsImage,
      link: 'https://github.com/parcllabs/parcllabs-examples',
    },
  ];

  return (
    <VStack spacing="4" align="stretch">
      <Text fontSize="xl" fontWeight="bold" color="white" alignSelf="start">Start building</Text>
      <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5} w="full">
        {items.map((item, index) => (
          <LinkBox 
            as={Box} 
            key={index} 
            p={5} 
            background="white" // Set background to transparent
            borderRadius="lg" 
            boxShadow="md" 
            _hover={{ bg: "gray.100" }} 
            cursor="pointer"
            border="1px solid" // Add border with 1px width
            borderColor="var(--primary-light)" // Set the border color to blue
          >
            <Image src={item.imageUrl} borderRadius="md" />
            <Text fontSize="lg" fontWeight="bold" mt={3}>
              <LinkOverlay href={item.link} isExternal>
                {item.title}
              </LinkOverlay>
            </Text>
            <Text fontSize="md" mt={1}>{item.subtext}</Text>
          </LinkBox>
        ))}
      </SimpleGrid>
    </VStack>
  );
};

export default StartBuildingStep;
