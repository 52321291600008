import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Heading, Text, VStack, HStack, Flex, Link, Icon } from '@chakra-ui/react';
import { createCheckoutSession, checkSubscription, createPortalSession } from '../../services/appService';
import { ExternalLinkIcon, TimeIcon, CalendarIcon } from '@chakra-ui/icons';
import { useUsage } from '../../contexts/UsageContext';
import { useRoles } from '../../contexts/allRolesContext';
import { useCurrentRole } from '../../contexts/currentRoleContext';
import { FaDatabase, FaMoneyBillWave, FaRegHandshake, FaChartLine, FaRocket, FaUnlock } from 'react-icons/fa';
import { formatRoleName } from '../../utils/formatUserRoleStrings';
import Cookies from 'js-cookie';


const BillingComponent = () => {

  window.dataLayer.push({
    event: 'billing_page_view',
    user_id: Cookies.get('user_id'),
  })

  const { usageMetrics } = useUsage();
  const { roles } = useRoles();
  const { currentRole } = useCurrentRole();
  const [planName, setPlanName] = useState('');
  const [renewalDate, setRenewalDate] = useState('');
  const [credits, setCredits] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [remainingCredits, setRemainingCredits] = useState(0);

  useEffect(() => {
    const fetchData = async () => {

      try {
        const [subscription] = await Promise.all([
          checkSubscription(),
        ]);
        if (currentRole) {
          setPlanName(formatRoleName(currentRole.user_role));
          setCredits(currentRole.credit_limit);
          setRemainingCredits(Math.max(currentRole.credit_limit - usageMetrics.cycle_credits, 0));
        }
        
        setIsSubscribed(subscription.subscribed);
        setRenewalDate(usageMetrics.cycle_end.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }));
        
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [usageMetrics, currentRole, roles]);

  const handleCheckout = async (lookup_key: string) => {
    try {
      const redirect = await createCheckoutSession(lookup_key);
      window.location.href = redirect.url;
      window.dataLayer.push({
        event: 'checkout_click',
        user_id: Cookies.get('user_id'),
        category: 'User-Billing',
        action: 'Clicked Billing Button',
        label: 'Clicked Upgrade Button',
      });
    } catch (error) {
      console.error('Error redirecting to checkout page:', error);
    }
  };

  const handleBillingPortal = async () => {
    try {
      const redirect = await createPortalSession();
      window.location.href = redirect.url;
      window.dataLayer.push({
        event: 'manage_account_click',
        user_id: Cookies.get('user_id'),
        category: 'User-Billing',
        action: 'Clicked Billing Button',
        label: 'Clicked Manage Account Button',
      });
    } catch (error) {
      console.error('Error redirecting to portal page:', error);
    }
  };

  return (
    <VStack spacing={10} align="stretch">
      <Box p={6} bg="gray.100" borderRadius="md" minHeight="250px" minWidth="400px" mt={10}>
        <HStack justify="space-between" align="center">
          <VStack align="start" spacing={6}>
            <Flex align="center">
              <Icon as={CalendarIcon} mr={2} w={5} h={5} color="var(--primary-medium)" />
              <Text fontSize="lg" fontWeight="bold">Current Active Plan:</Text>
              <Heading fontSize="lg" ml={2} color="var(--primary-medium)">{planName}</Heading>
            </Flex>
            <VStack align="start" spacing={3}>
              <Flex align="center">
                <Icon as={TimeIcon} mr={2} w={4} h={4} color="var(--primary-light)" />
                <Text fontSize="sm">Renews on: <strong>{renewalDate}</strong></Text>
              </Flex>
              <Flex align="center">
                <Icon as={FaDatabase} mr={2} w={4} h={4} color="var(--primary-light)" />
                <Text fontSize="sm">Monthly credits: <strong>{credits}</strong></Text>
              </Flex>
              <Flex align="center">
                <Icon as={FaChartLine} mr={2} w={4} h={4} color="var(--primary-light)" />
                <Text fontSize="sm">Remaining monthly credits: <strong>{remainingCredits}</strong></Text>
              </Flex>
            </VStack>
          </VStack>
        </HStack>
        <Box w="full" p={2} textAlign="center"></Box>
        <Box w="full" p={2} textAlign="center">
          <Link
            href="https://docs.parcllabs.com/docs/usage-limitations"
            isExternal
            color="var(--primary-medium)" // fix color
            fontSize="sm"
            display="inline-flex"
            alignItems="center"
            gap="2"
          >
            Learn more about credits and our pricing plans <ExternalLinkIcon mx="2px" />
          </Link>
        </Box>
      </Box>


      {/* Divider */}
      <Divider />

      {/* Conditionally Rendered Full-width Card for Plan Options or Subscription Management */}
      <Box p={6} bg="gray.50" w="full" borderRadius="md" minHeight="250px" minWidth="400px">
        <VStack align="start" spacing={6} h="full">
          {isSubscribed ? (
            <>
              <Heading fontSize="lg">Subscribed</Heading>
              <Text fontSize="sm">You are currently subscribed to our services.</Text>
              <Flex w="full" justifyContent="center" mt="auto">
                <Button colorScheme="blue" onClick={handleBillingPortal}>Manage Subscription</Button>
              </Flex>
            </>
          ) : (
            <>
              <Flex align="center">
                <Icon as={FaRocket} mr={2} w={5} h={5} color="var(--primary-medium)" />
                <Text fontSize="lg" fontWeight="bold">Get 100x More Data:</Text>
                <Heading fontSize="lg" ml={2} color="var(--primary-medium)">Starter Plan</Heading>
              </Flex>
              <VStack align="start" spacing={3}>
                <Flex align="center">
                  <Icon as={FaMoneyBillWave} mr={2} w={4} h={4} color="var(--primary-light)" />
                  <Text fontSize="sm">Monthly Subscription Cost: <strong>$99</strong></Text>
                </Flex>
                <Flex align="center">
                  <Icon as={FaDatabase} mr={2} w={4} h={4} color="var(--primary-light)" />
                  <Text fontSize="sm"><strong>100x</strong> more data</Text>
                </Flex>
                <Flex align="center">
                  <Icon as={FaUnlock} mr={2} w={4} h={4} color="var(--primary-light)" />
                  <Text fontSize="sm">
                  Access: All API endpoints, including <strong>unit-level data</strong>
                  </Text>
                </Flex>
                <Flex align="center">
                  <Icon as={FaRegHandshake} mr={2} w={4} h={4} color="var(--primary-light)" />
                  <Text fontSize="sm"><strong>Month to Month</strong>, Cancel Anytime</Text>
                </Flex>
                <Flex align="center">
                  <Icon as={FaChartLine} mr={2} w={4} h={4} color="var(--primary-light)" />
                  <Text fontSize="sm">
                  Monthly Credits: <strong>{roles ? roles['starter']?.credit_limit.toLocaleString() : 'Loading...'}</strong>
                  </Text>
                </Flex>
              </VStack>
              <Flex w="full" justifyContent="center" mt="auto" padding={4}>
                <Button
                  backgroundColor="var(--primary-light)" // Default background color
                  color="white" // Adjust text color as needed
                  _hover={{
                    backgroundColor: "var(--primary-medium)", // Background color on hover
                  }}
                  onClick={() => handleCheckout('starter_monthly')}
                >
                  Upgrade Now
                </Button>
              </Flex>
            </>
          )}
        </VStack>
      </Box>
    </VStack>
  );
};

export default BillingComponent;
