// Profile.tsx
import React from 'react';
import {
    Box, Flex, FormControl, FormLabel, Input, Button, VStack, Heading, FormErrorMessage
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import LogoutButton from '../../utils/logOut'; // Adjust the import path as necessary

interface ProfileFormInputs {
    email: string;
    firstName: string;
    lastName: string;
    accountTier: string;
}

const Profile: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<ProfileFormInputs>();
    const onSubmit = (data: ProfileFormInputs) => console.log(data);

    return (
        <Flex>
            <Box
                px={8}
                py={6}
                mx="auto"
                width={["90%", "80%", "70%", "400px"]}
                maxWidth="500px"
                borderRadius="lg"
                className="boxShadow"
                sx={{
                    backgroundColor: 'var(--background-dark)', // Updated to use dark background for contrast
                    boxShadow: 'var(--box-shadow)', // Ensure this is defined in your CSS
                }}
            >
                <VStack as="form" onSubmit={handleSubmit(onSubmit)} spacing={4} align="stretch">
                    <Heading as="h3" size="lg" color="white">My Profile</Heading> {/* Ensure text is readable against the dark background */}
                    {/* Email FormControl */}
                    <FormControl isInvalid={!!errors.email}>
                        <FormLabel htmlFor="email" color="var(--primary-light)">Email</FormLabel> {/* Updated label color for better visibility */}
                        <Input
                            id="email"
                            type="email"
                            {...register('email')}
                            sx={{
                                borderColor: 'var(--primary-medium)',
                                _hover: { borderColor: 'var(--primary-light)' },
                                _focus: {
                                    borderColor: 'var(--primary-light)',
                                    boxShadow: `0 0 0 1px var(--primary-light)`,
                                },
                                color: 'white', // Ensures input text is visible
                                backgroundColor: 'var(--primary-dark)', // Input background
                            }}
                        />
                        <FormErrorMessage>
                            {errors.email && errors.email.message}
                        </FormErrorMessage>
                    </FormControl>
    
                    {/* Repeat for First Name, Last Name, and Account Tier FormControls with the same styling adjustments */}
    
                    <Button
                        mt={4}
                        type="submit"
                        sx={{
                            backgroundColor: 'var(--primary-medium)',
                            color: 'white', // Adjusted for readability
                            _hover: {
                                bg: 'var(--primary-light)',
                                transform: 'scale(1.05)',
                            },
                            _focus: {
                                boxShadow: '0 0 0 2px var(--primary-very-light)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        Update Profile
                    </Button>
                    <LogoutButton />
                </VStack>
            </Box>
        </Flex>
    );
};

export default Profile;