import React from 'react';
import { Box, Text, Flex, IconButton } from '@chakra-ui/react';
import { RepeatIcon, TimeIcon } from '@chakra-ui/icons'; // Import the refresh and time icons
import { AnalyticsCardProps } from './types';

const AnalyticsCard: React.FC<AnalyticsCardProps> = ({
  metricName,
  interval,
  startDate,
  endDate,
  value,
  onRefresh, // This prop will be passed to handle the refresh logic
}) => {
  let intervalDisplay;
  if (interval === 'Cycle' && startDate && endDate) {
    intervalDisplay = `${startDate} to ${endDate}`;
  } else if (interval === 'Lifetime') {
    // Use TimeIcon for Lifetime interval
    intervalDisplay = <TimeIcon boxSize="4" />;
  } else {
    intervalDisplay = 'Last 24 Hours';
  }

  return (
    <Box borderWidth="1px" borderRadius="lg" p="6" bg="white" borderColor="gray.200" width="100%" height="250px" position="relative" boxShadow="sm">
      <Flex justify="space-between" mb="4">
        <Text fontSize="md" fontWeight="bold" color="gray.800">{metricName}</Text>
        {/* Use a Flex container for the intervalDisplay to properly align the TimeIcon with the text */}
        <Flex alignItems="center" fontSize="md" fontWeight="bold" color="gray.800">
          {intervalDisplay}
        </Flex>
      </Flex>
      <Flex height="100%" align="center" justify="center">
        <Text fontSize="5xl" fontWeight="bold" color="gray.800" textAlign="center">{value}</Text>
      </Flex>
      {/* Refresh Button */}
      <IconButton
        icon={<RepeatIcon />}
        isRound
        size="sm"
        position="absolute"
        bottom="2"
        right="2"
        onClick={onRefresh}
        aria-label="Refresh"
      />
    </Box>
  );
};

export default AnalyticsCard;
