import React, { createContext, useContext, useState, useEffect, ReactNode, useMemo } from 'react';
import { ApiRolesResponse, getAllRoles } from '../services/appService';
import Cookies from 'js-cookie';

interface RolesContextType {
  roles: ApiRolesResponse | null;
  loading: boolean;
}

const RolesContext = createContext<RolesContextType | undefined>(undefined);

const fetchRoles = async (): Promise<ApiRolesResponse> => {
  const accessToken = Cookies.get('AccessToken');
  return await getAllRoles(accessToken!);
};

export const RolesProvider = ({ children }: { children: ReactNode }) => {
  const [roles, setRoles] = useState<ApiRolesResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchAndSetRoles = async () => {
      try {
        const roles = await fetchRoles();
        setRoles(roles);
      } catch (error) {
        console.error('Failed to fetch roles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndSetRoles();
  }, []);

  const value = useMemo(() => ({ roles, loading }), [roles, loading]);

  return (
    <RolesContext.Provider value={value}>
      {children}
    </RolesContext.Provider>
  );
};

export const useRoles = (): RolesContextType => {
  const context = useContext(RolesContext);
  if (context === undefined) {
    throw new Error('useRoles must be used within a RolesProvider');
  }
  return context;
};
