import React from 'react';
import { VStack, Text, Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

interface LinkItem {
  text: string;
  to: string;
  as?: any;
}

interface FormFooterLinksProps {
  links: LinkItem[];
}

export const FormFooterLinks: React.FC<FormFooterLinksProps> = ({ links }) => {
  return (
    <VStack spacing={0}>
      {links.map((link, index) => (
        <Text key={index} textAlign="center" mt={index > 0 ? 0 : 2} fontSize="sm" color="var(--primary-very-light)">
          {link.text}{' '}
          <ChakraLink as={link.as || RouterLink} to={link.to} sx={{ color: 'var(--primary-light)', _hover: { textDecoration: 'underline' } }}>
            here
          </ChakraLink>
        </Text>
      ))}
    </VStack>
  );
};
