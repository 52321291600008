import React from 'react';
import { Box, Text, useTheme, Link } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { UpdateCardProps, labelGradients } from './types';

const UpdateCard: React.FC<UpdateCardProps> = ({ label, title, description, href, labelType, date }) => {
  const theme = useTheme();
  const gradient = labelGradients[labelType] || theme.colors.gray[300]; // Default to gray if labelType not found

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      padding="6"
      marginRight="4"
      position="relative"
      bgGradient={gradient}
      minHeight="300"
      width="250" // Fixed width for the update card
      maxWidth="300px" // Ensures that the card does not exceed this width
    >
      <Box position="absolute" top="2" left="2" bg="white" padding="1" borderRadius="md">
        <Text fontSize="xs" fontWeight="bold" colorScheme="teal">
          {label}
        </Text>
      </Box>

      <Text fontSize="lg" fontWeight="bold" mt="8" color="white" noOfLines={2}>
        {title}
      </Text>
      <Text fontSize="md" mt="2" color="white" noOfLines={4}>
        {description}
      </Text>
      <Link href={href} isExternal position="absolute" bottom="4" right="4">
        <ArrowForwardIcon color="white" />
      </Link>
      {/* Date display */}
      <Text fontSize="sm" position="absolute" bottom="4" left="4" color="white">
        {date}
      </Text>
    </Box>
  );
};

export default UpdateCard;
