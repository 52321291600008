import React, { useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, Box, Flex, Text, VStack, Button, IconButton, useTheme, Heading, Icon } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaTimes, FaDatabase, FaMoneyBillWave, FaChartLine, FaLock, FaUnlock } from 'react-icons/fa';
import { CalendarIcon } from '@chakra-ui/icons';
import { createCheckoutSession } from '../../services/appService';
import { useRoles } from '../../contexts/allRolesContext';
import { useCurrentRole } from '../../contexts/currentRoleContext';
import { formatRoleName } from '../../utils/formatUserRoleStrings';
import Cookies from 'js-cookie';

interface BillingUpgradeProps {
  isOpen: boolean;
  onClose: () => void;
  renewalDate: string;
  remainingCredits: number;
}

const MotionFlex = motion(Flex);

const BillingUpgrade: React.FC<BillingUpgradeProps> = ({ isOpen, onClose, renewalDate, remainingCredits }) => {
  const theme = useTheme();
  const { roles } = useRoles();
  const { currentRole } = useCurrentRole();

  useEffect(() => {
    if (isOpen) {
      // Push event to GTM data layer when the modal is displayed
      window.dataLayer.push({
        event: 'billing_modal_display',
        user_id: Cookies.get('user_id'),
        modal_name: 'BillingUpgrade',
        modal_status: 'open'
      });
    }
  }, [isOpen]);

  const handleCheckout = async (lookup_key: string) => {
    try {
      const redirect = await createCheckoutSession(lookup_key);
      window.location.href = redirect.url;
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'checkout_click',
        user_id: Cookies.get('user_id'),
        category: 'User-Billing',
        action: 'Clicked Billing Button',
        label: 'Clicked Upgrade Button',
      });
    } catch (error) {
      console.error('Error redirecting to checkout page:', error);
    }
  };

  const handleClose = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'upgrade_modal_close',
      user_id: Cookies.get('user_id'),
      modal_name: 'BillingUpgrade',
      modal_status: 'closed'
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered size="4xl">
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent borderRadius="lg" overflow="hidden" bg={theme.colors.background.light}>
        <ModalBody p={6}>
          <MotionFlex
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            direction="column"
            alignItems="center"
            overflowX="hidden"
          >
            <Flex align="center" justify="space-between" width="full">
              <Text fontSize="2xl" fontWeight="bold" color="var(--primary-color)">
                You May Need More Credits: Upgrade Now to Keep Building
              </Text>
              <IconButton
                aria-label="Close modal"
                size="sm"
                onClick={handleClose}
                icon={<FaTimes />}
                variant="ghost"
                _hover={{}}
                _focus={{}}
                _active={{}}
              />
            </Flex>

            <Flex direction={{ base: 'column', md: 'row' }} gap={4} mt={4} width="full">
              {/* Current Plan */}
              <Box p={4} bg="gray.50" borderRadius="md" flex="1" mb={{ base: 4, md: 0 }}>
                <Heading fontSize="lg" color="gray.600" mb={2}>
                  Your Current Plan: {currentRole ? formatRoleName(currentRole.user_role) : 'Loading...'}
                </Heading>
                <VStack align="start" spacing={3}>
                  <Flex align="center">
                    <Icon as={CalendarIcon} mr={2} w={5} h={5} color="gray.600" />
                    <Text fontSize="sm" color="gray.600">Renews on: <strong>{renewalDate}</strong></Text>
                  </Flex>
                  <Flex align="center">
                    <Icon as={FaDatabase} mr={2} w={4} h={4} color="gray.600" />
                    <Text fontSize="sm" color="gray.600">Monthly credits: <strong>{roles ? roles['basic']?.credit_limit.toLocaleString() : 'Loading...'}</strong></Text>
                  </Flex>
                  <Flex align="center">
                    <Icon as={FaLock} mr={2} w={4} h={4} color="gray.600" />
                    <Text fontSize="sm" color="gray.600">Access: <strong> Restricted</strong></Text>
                  </Flex>
                  <Flex align="center">
                    <Icon as={FaChartLine} mr={2} w={4} h={4} color="gray.600" />
                    <Text fontSize="sm" color="gray.600">Remaining credits: <strong style={{ color: 'red' }}>{remainingCredits}</strong></Text>
                  </Flex>
                  <Box w="full" p={2} textAlign="left">
                    <Text fontSize="sm" color="red">
                      You have run out of credits. You can wait until {renewalDate} to continue, or you can upgrade for{' '}
                      {
                        roles && roles['starter'] && roles['starter'].credit_limit && roles['basic'] && roles['basic'].credit_limit
                          ? Math.round(roles['starter'].credit_limit / roles['basic'].credit_limit)
                          : 'N/A'
                      }x more data to keep cooking
                      <span role="img" aria-label="cooking">🍳</span>
                      <span style={{ color: 'blue' }}>➡️</span>
                    </Text>
                  </Box>
                </VStack>
              </Box>

              {/* Upgrade Plan */}
              <Box p={4} bg="blue.50" borderRadius="md" flex="1">
                <Heading fontSize="lg" color="var(--primary-medium)" mb={2}>Starter Plan</Heading>
                <VStack align="start" spacing={3} h="full">
                  <Flex align="center">
                    <Icon as={FaMoneyBillWave} mr={2} w={4} h={4} color="var(--primary-light)" />
                    <Text fontSize="sm"><strong>Month to Month</strong>, Cancel Anytime</Text>
                  </Flex>
                  <Flex align="center">
                    <Icon as={FaChartLine} mr={2} w={4} h={4} color="var(--primary-light)" />
                    <Text fontSize="sm">
                      Monthly Credits: <strong>{roles ? roles['starter']?.credit_limit.toLocaleString() : 'Loading...'} credits</strong>
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon as={FaUnlock} mr={2} w={4} h={4} color="var(--primary-light)" />
                    <Text fontSize="sm">
                      Access: All API endpoints, including <strong>unit-level data</strong>
                    </Text>
                  </Flex>
                  <Flex align="center">
                    <Icon as={FaMoneyBillWave} mr={2} w={4} h={4} color="var(--primary-light)" />
                    <Text fontSize="sm">Monthly Subscription Cost: <strong>$99</strong></Text>
                  </Flex>
                  <Flex align="center">
                    <Icon as={FaDatabase} mr={2} w={4} h={4} color="var(--primary-light)" />
                    <Text fontSize="sm"><strong>100x</strong> more data</Text>
                  </Flex>
                  <Text fontSize="sm">Upgrade now for only $99/month and receive 100x more data credits.</Text>

                  <Flex w="full" justifyContent="center" mt={2} padding={2}>
                    <Button
                      backgroundColor="var(--primary-light)"
                      color="white"
                      width="200px" // Adjust width as needed
                      _hover={{
                        backgroundColor: "var(--primary-medium)",
                      }}
                      onClick={() => handleCheckout('starter_monthly')}
                    >
                      Upgrade Now
                    </Button>
                  </Flex>
                </VStack>
              </Box>
            </Flex>
          </MotionFlex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BillingUpgrade;
