import React, { useEffect, useState } from 'react';
import JustShippedContainer from './JustShippedContainer';
import UpdateCard from './UpdateCard';
import { UpdateCardProps } from './types';
import { getJustShipped, JustShippedResponse } from '../../../services/appService';

const JustShippedUpdates: React.FC = () => {
  const [updates, setUpdates] = useState<UpdateCardProps[]>([]);

  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        const shippedUpdates = await getJustShipped(9);
        const mappedUpdates: UpdateCardProps[] = shippedUpdates.map((update: JustShippedResponse) => ({
          label: update.label,
          title: update.title,
          description: update.description,
          href: update.href,
          labelType: update.label_type as "RESEARCH" | "PRESS" | "API", // Cast to specific union type
          date: update.date.toDateString(), // Conversion to string for display
        }));

        // Sort the updates in descending order by date
        mappedUpdates.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB.getTime() - dateA.getTime(); // For descending order
        });

        setUpdates(mappedUpdates);
      } catch (error) {
        console.error('Failed to fetch just shipped updates:', error);
      }
    };

    fetchUpdates();
  }, []);

  return (
    <JustShippedContainer>
      {updates.map((update, index) => (
        <UpdateCard key={index} {...update} />
      ))}
    </JustShippedContainer>
  );
};

export default JustShippedUpdates;
