import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, useToast, VStack, Spinner, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AuthFormWrapper } from './AuthFormWrapper';
import { AuthFormField } from './AuthFormField';
import { FormFooterLinks } from './common/FormFooterLinks';
import Logo from "../../common/Logo";
import { signIn } from '../../../services/cognitoService';
import Cookies from 'js-cookie';
import { createApiUser, getUserApiKey, getUserRole, getUsage } from '../../../services/appService';

interface LoginFormData {
  email: string;
  password: string;
}

interface Session {
  idToken: { jwtToken: string };
  accessToken: { jwtToken: string };
  refreshToken: { jwtToken: string };
}

export const LoginPage: React.FC = () => {

  window.dataLayer.push({
    event: 'login_page_view',
    page: {
      url: window.location.href,
      title: document.title,
    }
  })

  const toast = useToast();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm<LoginFormData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (data: LoginFormData) => {
    setIsLoading(true);
    try {
      const session: Session = await signIn(data.email, data.password);
      if (session.idToken && session.accessToken) {
        setSessionCookies(session);
        const accessToken = session.accessToken.jwtToken;
        // Do Not Delete, dont need to use response object
        const _createUserResponse = await createApiUser(accessToken);
        const apiKeyResponse = await getUserApiKey(accessToken);
        const userRoleResponse = await getUserRole(accessToken);
        const usageMetrics = await getUsage(accessToken);

        const isNewUser = usageMetrics.lifetime_credits === 0 ? '1' : '0';
        Cookies.set('is_new_user', isNewUser);
      
        Cookies.set('apiKey', apiKeyResponse.api_key);
        Cookies.set('user_role', userRoleResponse.user_role);
        Cookies.set('user_id', userRoleResponse.user_id);
        showToastAndNavigate();
        window.dataLayer.push({
          event: 'login',
          category: 'User-Login',
          user_id: userRoleResponse.user_id,
          action: 'Clicked Button',
          label: 'Clicked Login Button',
        });
      }
    } catch (error) {
      handleLoginError(error);
    }
  };

  const setSessionCookies = (session: Session): void => {
    Cookies.set('AccessToken', session.accessToken.jwtToken);
  };

  const showToastAndNavigate = (): void => {
    toast({
      title: "Login successful",
      description: "You're now logged in.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate('/');
    setIsLoading(false);
  };

  const handleLoginError = (error: any): void => {
    setIsLoading(false);
    let descriptionElement = <span>An unexpected error occurred</span>;
  
    // Check if the error is an instance of Error and customize the message for "User is not confirmed"
    if (error instanceof Error) {
      if (error.message.includes("User is not confirmed")) {
        descriptionElement = (
          <span>
            Please email <a href="mailto:team@parcllabs.com" style={{ textDecoration: 'underline' }}>team@parcllabs.com</a> for support.
          </span>
        );
      } else {
        descriptionElement = <span>{error.message}</span>;
      }
    }
  
    toast({
      title: "Login failed",
      description: descriptionElement,
      status: "error",
      duration: 9000,
      isClosable: true,
    });
  
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'login_error',
      user_id: Cookies.get('user_id'),
      category: 'User-Login',
      action: 'Login Error',
      label: 'Login failed with error: ' + (typeof descriptionElement === "string" ? descriptionElement : error.message),
    });
  };

  return (
      <AuthFormWrapper isLoading={isLoading}>
        {isLoading ? (
          <Center height="100vh">
            <Spinner color="white" size="xl" thickness="4px" speed="0.65s" emptyColor="gray.200" />
          </Center>
        ) : (
          <VStack as="form" onSubmit={handleSubmit(onSubmit)} spacing={4} align="stretch">
            <AuthFormField
              id="email"
              label="Email"
              type="email"
              register={register}
              validationRule={{ required: 'Email is required' }}
              isInvalid={!!errors.email}
            />
            <AuthFormField
              id="password"
              label="Password"
              type="password"
              register={register}
              validationRule={{ required: 'Password is required' }}
              isInvalid={!!errors.password}
            />
            <Button
              mt={4}
              type="submit"
              sx={{
                backgroundColor: 'var(--primary-medium)',
                color: 'white',
                _hover: { bg: 'var(--primary-light)' },
                _focus: { boxShadow: '0 0 0 2px var(--primary-very-light)' },
                transition: 'all 0.3s ease',
              }}
            >
              Log In
            </Button>
            <FormFooterLinks
              links={[
                {
                  text: 'Forgot your password? Reset it',
                  to: "/reset-password",
                },
                {
                  text: "Don't have an account? Sign up",
                  to: "/signup",
                },
              ]}
            />
            <Logo />
          </VStack>
        )}
      </AuthFormWrapper>
  );
};
