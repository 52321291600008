import axios from 'axios';
import Cookies from 'js-cookie';

import { APP_BACKEND_ENDPOINTS } from '../config/apiConfig';


// Create User wrapper
export interface ApiUserResponse {
  user_id: string;
  message: string;
  userCreated: number;
  last_login: Date;
}

export interface ApiUserRoleResponse {
  user_role: string;
  user_id: string;
  credit_limit: number;
}

export async function getUserRole(accessToken: string): Promise<ApiUserRoleResponse> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(APP_BACKEND_ENDPOINTS.GET_USER_ROLE, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      resolve({
        user_role: response.data.user_role,
        user_id: response.data.user_id,
        credit_limit: response.data.credit_limit
      });

    } catch (error) {
      console.error('Error getting user role:', error);
      reject(error);
    }
  }
)};

export async function createApiUser(accessToken: string): Promise<ApiUserResponse> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(APP_BACKEND_ENDPOINTS.CREATE_API_USER, {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      resolve({
        user_id: response.data.user_id,
        message: response.data.message,
        userCreated: response.data.user_created,
        last_login: new Date(response.data.last_login)
      });

    } catch (error) {
      console.error('Error creating API user:', error);
      reject(error);
    }
  });
}

export interface ApiRoleResponse {
  id: number;
  role_name: string;
  is_active: boolean;
  credit_id: number;
  credit_limit: number;
  created_at: Date;
  updated_at: Date;
}

export interface ApiRolesResponse {
  [roleName: string]: ApiRoleResponse;
}

export async function getAllRoles(accessToken: string): Promise<ApiRolesResponse> {
  try {
    const response = await axios.get(APP_BACKEND_ENDPOINTS.GET_ALL_ROLES, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    // Access the roles array properly
    const rolesArray = response.data.roles;

    // Check if rolesArray is an array
    if (!Array.isArray(rolesArray)) {
      throw new TypeError('Expected an array in response.data.roles');
    }

    // Create an empty object to accumulate the roles
    const rolesResponse: ApiRolesResponse = rolesArray.reduce((acc: ApiRolesResponse, item: any) => {
      acc[item.role_name] = {
        id: item.id,
        role_name: item.role_name,
        is_active: item.is_active,
        credit_id: item.credit_id,
        credit_limit: item.credit_limit,
        created_at: new Date(item.created_at),
        updated_at: new Date(item.updated_at),
      };
      return acc;
    }, {});

    // Return the accumulated roles object
    return rolesResponse;
  } catch (error) {
    console.error('Error getting user roles:', error);
    throw error;
  }
}

interface ApiKeyResponse {
  api_key: string;
}

export async function getUserApiKey(accessToken: string): Promise<ApiKeyResponse> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(APP_BACKEND_ENDPOINTS.GET_API_KEY, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      resolve({
        api_key: response.data.token
      });
    } catch (error) {
      console.error('Error getting API key:', error);
      reject(error);
    }
  });
}


export interface UsageResponse {
  lifetime_credits: number;
  lifetime_api_requests: number;
  cycle_credits: number;
  cycle_api_requests: number;
  cycle_count: number;
  cycle_end: Date;
  cycle_start: Date;
}

export async function getUsage(accessToken: string): Promise<UsageResponse> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(APP_BACKEND_ENDPOINTS.USAGE, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
      resolve({
        lifetime_credits: response.data.lifetime_credits,
        lifetime_api_requests: response.data.lifetime_api_requests,
        cycle_credits: response.data.cycle_credits,
        cycle_api_requests: response.data.cycle_api_requests,
        cycle_count: response.data.cycle_count,
        cycle_end: new Date(response.data.cycle_end),
        cycle_start: new Date(response.data.cycle_start)
      });
    } catch (error) {
      console.error('Error capturing usage:', error);
      reject(error);
    }
  });
}

export interface JustShippedResponse {
  label: string;
  title: string;
  description: string;
  href: string;
  label_type: string;
  date: Date;
}

export async function getJustShipped(limit: number): Promise<JustShippedResponse[]> {
  return new Promise(async (resolve, reject) => {
    try {
      // Pass 'limit' as a query parameter to the GET request
      const response = await axios.get(`${APP_BACKEND_ENDPOINTS.SHIPPED}/?limit=${limit}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Assuming response.data is an array of objects
      const shippedData = response.data.map((item: any) => ({
        label: item.label,
        title: item.title,
        description: item.description,
        href: item.href,
        label_type: item.label_type,
        date: new Date(item.date) // Convert the date string to a Date object for each item
      }));

      resolve(shippedData);

    } catch (error) {
      console.error('Error fetching shipped data:', error);
      reject(error);
    }
  });
}

export interface CreateSessionResponse {
  url: string;
};


export async function createCheckoutSession(lookupKey: string): Promise<CreateSessionResponse> {
  const accessToken = Cookies.get('AccessToken');

  const data = {
    lookup_key: lookupKey
  };

  const config = {
    headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    } 
  };

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(APP_BACKEND_ENDPOINTS.CREATE_CHECKOUT_SESSION, data, config);
  
      resolve({
        url: response.data.url,
      });

    } catch (error) {
      console.error('Error creating checkout session:', error);
      reject(error);
    }
  });
}

export async function createPortalSession(): Promise<CreateSessionResponse> {
  const accessToken = Cookies.get('AccessToken');

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get('session_id');

  const data = {
    session_id: sessionId
  }

  const config = {
    headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    } 
  };

  return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(APP_BACKEND_ENDPOINTS.CREATE_PORTAL_SESSION, data, config);

        resolve({
          url: response.data.url,
        })

      } catch (error) {
        console.error('Error creating portal session:', error);
        reject(error);
      }
    });
}


export interface SubscriptionStatusResponse {
  subscribed: boolean;
};


export async function checkSubscription(): Promise<SubscriptionStatusResponse> {
  const accessToken = Cookies.get('AccessToken');

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(APP_BACKEND_ENDPOINTS.CHECK_SUBSCRIPTION, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      resolve({
        subscribed: response.data.subscribed,
      });

    } catch (error) {
      //console.error('Error checking subscription:', error);
      resolve({
        subscribed: false
      });
      //reject(error);
    }
  });
}