import { OnboardingStep } from './types';
import ViewApiKeyStep from './Step_2_ViewAPIKeyStep';
import WelcomeStep from './Step_1_WelcomeStep';
import SendFirstRequest from './Step_3_SendFirstRequest';
import StartBuilding from './Step_4_StartBuilding';

const onboardingSteps: OnboardingStep[] = [
  {
    id: 1,
    title: 'Welcome to Parcl Labs API',
    component: WelcomeStep,
  },
  {
    id: 2,
    title: 'View Your API Key',
    component: ViewApiKeyStep,
  },
  {
    id: 3,
    title: 'Send First Request',
    component: SendFirstRequest,
  },
  {
    id: 4,
    title: 'Start Building',
    component: StartBuilding,
  },
];

export default onboardingSteps;