import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

interface JustShippedContainerProps {
  children: React.ReactNode;
}

const JustShippedContainer: React.FC<JustShippedContainerProps> = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const cardsPerGroup = 4;
  const childrenArray = React.Children.toArray(children);
  const childrenCount = childrenArray.length;
  const pageCount = Math.ceil(childrenCount / cardsPerGroup);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const scrollLeft = container.scrollLeft;
      const cardWidth = 300 + 4; // Card width + marginRight
      const groupScrollWidth = cardWidth * cardsPerGroup;
      const index = Math.round(scrollLeft / groupScrollWidth);
      setActiveIndex(index);
    }
  };

  const scrollToGroup = (index: number) => {
    const container = containerRef.current;
    if (container) {
      const cardWidth = 300 + 16; // Card width + marginRight
      const groupScrollWidth = cardWidth * cardsPerGroup;
      const scrollTo = groupScrollWidth * index;
      container.scrollLeft = scrollTo;
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll, { passive: true });
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <Box
      bg="gray.100"
      w="100%"
      maxW="100vw"
      paddingX="3"
      paddingY="4"
      marginX="auto"
      borderRadius="lg"
      overflowX="hidden"
      overflowY="hidden"
    >
      <Text fontSize="xl" fontWeight="bold" p="0" bg="transparent" color="black" borderBottomWidth="1px" borderColor="gray.300">
        Just Shipped 🚀
      </Text>
      <Flex
        direction="row"
        overflowX="auto"
        paddingY="2"
        ref={containerRef}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {childrenArray.map((child, index) => (
          <Box minWidth="300px" height="300px" marginRight="4" flex="none" key={index}>
            {child}
          </Box>
        ))}
      </Flex>
      <Flex justifyContent="center" mt="2">
        {Array.from({ length: pageCount }).map((_, index) => (
          <Box
            key={index}
            width="20px"
            height="2px"
            bg={activeIndex === index ? 'blue.500' : 'gray.300'}
            mx="1"
            cursor="pointer"
            onClick={() => scrollToGroup(index)}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default JustShippedContainer;
