import React from 'react';
import {
  Flex, useColorModeValue, Box, Text, Menu, MenuButton, MenuList, MenuItem, IconButton
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { BiLogOut, BiCog } from 'react-icons/bi';
import NavigationPane from './NavigationPane';
import { Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import { signOut } from '../../services/cognitoService';

const DashboardLayout: React.FC = () => {
  const navigate = useNavigate();
  const boxShadowColor = useColorModeValue('0px 0px 15px rgba(0, 0, 0, 0.1)', '0px 0px 15px rgba(255, 255, 255, 0.1)');

  const logout = () => {
    signOut(Cookies.get('AccessToken')!);
    Object.keys(Cookies.get()).forEach(cookieName => Cookies.remove(cookieName));
    navigate('/login'); // Redirect to login page after logout
  };

  const getGreeting = () => {
    const hour = new Date().getHours();
    return hour < 12 ? "Good Morning 🧪🥼" : hour < 18 ? "Good Afternoon 🧪🥼" : "Good Evening 🧪🥼";
  };

  return (
    <Flex
      h="100vh"
      direction="row"
      overflowX="hidden"
      sx={{
        bg: 'var(--background-dark)',
        maxWidth: '100vw',
        boxSizing: 'border-box',
      }}
    >
      <Box
        position="fixed"
        left="0"
        top="0"
        h="100vh"
        w="200px"
        overflowY="auto"
        sx={{
          boxShadow: boxShadowColor,
        }}
      >
        <NavigationPane />
      </Box>

      <Box
        pl="250px"
        w="full"
        h="100vh"
        overflowY="auto"
      >
        <Flex
          direction="column"
          sx={{
            paddingRight: '16px',
            paddingLeft: '0px',
            paddingTop: '16px',
            boxSizing: 'border-box',
            width: '100%',
          }}
        >
          <Flex
            as="header"
            align="center"
            justify="space-between"
            p={4}
            bg="transparent"
            borderRadius="lg"
            border="1px"
            borderColor="white"
          >
            <Text color="white" fontSize="2xl" flexGrow={1} textAlign="center">{getGreeting()}</Text>
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<BiCog />}
                variant="outline"
                colorScheme="white"
                aria-label="Settings"
                sx={{
                  _hover: {
                    bg: 'var(--primary-medium)', // Change background on hover
                  }
                }}
              />
              <MenuList>
                <MenuItem icon={<BiLogOut />} onClick={logout}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Flex>

          <Flex
            as="main"
            flex="1"
            p={0}
            direction="row"
            align="center"
            width="100%"
            justify="space-between"
            overflowY="auto"
          >
            <Outlet />
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default DashboardLayout;
