// HomeView.tsx
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import DashboardContainer from './DashboardContainer';
import DashboardSection from './DashboardSection';
import OnboardingGuide from '../../onboarding/OnboardingGuide';
import AllOnboardingSteps from '../../onboarding/AllOnboardingSteps';
import JustShippedUpdates from '../Cards/JustShippedUpdates';
import AnalyticUpdates from '../Cards/Usage/AnalyticUpdates';
import MyAppUpdates from '../Cards/Apps/MyAppUpdates';
import { useUsage } from '../../../contexts/UsageContext';
import { CreditProvider, useCredit } from '../../../contexts/creditContext';

const HomeView: React.FC = () => {
  const [isOnboardingOpen, setIsOnboardingOpen] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const apiKey = Cookies.get('apiKey');
  const { usageMetrics } = useUsage();
  const { checkCredits } = useCredit();

  useEffect(() => {
    if (usageMetrics !== undefined) {
      setIsOnboardingOpen(Cookies.get('is_new_user') === '1');
      checkCredits(); // Check credits whenever usage metrics change
      setIsLoading(false);
    }
  }, [usageMetrics, checkCredits]);

  if (isLoading || isOnboardingOpen === null) {
    return <div>Loading...</div>;
  }

  return (
    <DashboardContainer>
      <DashboardSection title="Welcome">
        <MyAppUpdates />
      </DashboardSection>
      <DashboardSection title="Usage">
        <AnalyticUpdates />
      </DashboardSection>
      <DashboardSection title="">
        <JustShippedUpdates />
      </DashboardSection>
      {isOnboardingOpen && (
        <OnboardingGuide apiKey={apiKey} steps={AllOnboardingSteps} onClose={() => setIsOnboardingOpen(false)} />
      )}
    </DashboardContainer>
  );
};

export default HomeView;
