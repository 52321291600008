import React from 'react';
import { FormControl, FormLabel, Input, FormControlProps } from '@chakra-ui/react';
import { FieldValues, UseFormRegister, Path } from 'react-hook-form';

// Adjust to use Path<T> for the id prop
interface AuthFormFieldProps<T extends FieldValues> extends FormControlProps {
  id: Path<T>; // Using Path<T> to match the expectations of register
  label: string;
  type: string;
  register: UseFormRegister<T>;
  validationRule: Record<string, any>;
}

export const AuthFormField = <T extends FieldValues>({
  id,
  label,
  type,
  register,
  validationRule,
  ...formControlProps
}: AuthFormFieldProps<T>) => {
  return (
    <FormControl {...formControlProps} isRequired>
      <FormLabel htmlFor={String(id)} color="var(--primary-very-light)">{label}</FormLabel>
      <Input
        id={String(id)}
        type={type}
        {...register(id, validationRule)}
        sx={{
          borderColor: 'var(--primary-medium)',
          _hover: { borderColor: 'var(--primary-light)' },
          _focus: {
            borderColor: 'var(--primary-light)',
            boxShadow: `0 0 0 1px var(--primary-light)`,
          },
          color: 'var(--primary-very-light)',
          backgroundColor: 'var(--primary-dark)',
        }}
      />
    </FormControl>
  );
};
