import React from 'react';
import { Box } from '@chakra-ui/react';

interface DashboardContainerProps {
  children: React.ReactNode;
}

const DashboardContainer: React.FC<DashboardContainerProps> = ({ children }) => {
  return (
    <Box
      h="100vh"
      w="100vw"
      overflowY="auto"
      overflowX="hidden"
      //width={`calc(100vw - ${navPaneWidth}px)`} // Dynamically calculate the width
      px="0" // Apply padding here if it does not cause overflow
      boxSizing="border-box" // Include padding in the width calculation
      bg="var(--primary-very-light)'"
    >
      {children}
    </Box>
  );
};

export default DashboardContainer;
