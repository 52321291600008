// CopyBox.tsx
import React from 'react';
import { Box } from '@chakra-ui/react';
import CopyButton from '../../../common/CopyButton';

interface CopyBoxProps {
    text: string;
}

const CopyBoxApps: React.FC<CopyBoxProps> = ({ text }) => {

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={3}
            mb={1}
            borderWidth="1px"
            borderRadius="lg"
            borderColor="black"
            position="relative"
            // Ensure the container fills its content to the edges
            maxW={{ base: '100%' }}
            height="100%"
        >
            <Box 
            display="flex"
                alignItems="center"
                justifyContent="start"
                //borderLeftWidth="0px"
                //borderRightWidth="0px"
                borderTopRightRadius="lg"
                borderBottomRightRadius="lg"
                height="100%"
                pl={4}
                pr={4}
                mb={1}
                pt={2}
                pb={2}
            color="black" flex="1" mr={4}>{text}
            </Box>
            
            <CopyButton text={text}/>  
            
        </Box>
    );
};

export default CopyBoxApps; 