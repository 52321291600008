import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  Flex,
  Text,
  VStack,
  Progress,
  Circle,
  Button,
  useDisclosure,
  IconButton,
  useTheme,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { OnboardingProcessProps } from './types';
import { FaTimes } from 'react-icons/fa';
import Cookies from 'js-cookie';

const MotionFlex = motion(Flex);

const OnboardingGuide: React.FC<OnboardingProcessProps> = ({ steps, initialStep = 0, apiKey }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(initialStep);
  const theme = useTheme();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  window.dataLayer.push({
    event: 'tutorial_begin',
    user_id: Cookies.get('user_id'),
  })

  const handleNextStep = () => {
    setCurrentStepIndex(prevIndex => {
      if (prevIndex < steps.length - 1) {
        const newIndex = prevIndex + 1;
        trackStepChange(newIndex); // Track the step change
        return newIndex;
      }
      return prevIndex;
    });
  };

  const handlePreviousStep = () => {
    setCurrentStepIndex(prevIndex => {
      if (prevIndex > 0) {
        const newIndex = prevIndex - 1;
        trackStepChange(newIndex); // Track the step change
        return newIndex;
      }
      return prevIndex;
    });
  };

  // Track direct step selections via step buttons
  const handleSetStepIndex = (index: number) => {
    setCurrentStepIndex(index);
    trackStepChange(index); // Track the step change
  };

  // Function to send event to Google Analytics
  const trackStepChange = (stepIndex: number) => {
    if (stepIndex === steps.length - 1) {
      // Send event for tutorial_complete
      window.dataLayer.push({
        event: 'tutorial_complete',
        user_id: Cookies.get('user_id'),
      });
    } else {
      // Send event for onboarding_step_change
      window.dataLayer.push({
        event: 'tutorial_step_change',
        user_id: Cookies.get('user_id'),
        step: {
          index: stepIndex + 1,
          title: steps[stepIndex].title
        },
      });
    }
  };

  const currentStepComponent = steps[currentStepIndex]?.component || (() => <Box>No Component</Box>);
  const completionPercentage = ((currentStepIndex + 1) / steps.length) * 100;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent borderRadius="lg" overflow="hidden" bg={theme.colors.background.light}>
        <ModalBody p={6}>
          <MotionFlex
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            direction="column"
            alignItems="center"
            overflowX="hidden"
          >
            <Flex align="center" justify="space-between" width="full">
              <Text fontSize="2xl" fontWeight="bold" color="var(--primary-color)">
                Onboarding Guide
              </Text>
              <IconButton
                aria-label="Close modal"
                size="sm"
                onClick={onClose}
                icon={<FaTimes />}
                variant="ghost"
                sx={{
                  color: 'var(--primary-dark)', // Sets the icon color
                  _hover: {
                    bg: 'var(--primary-light)', // Sets the background color on hover
                    borderRadius: '50%', // Makes the background circle
                    border: 'transparent', // Ensures there's no border
                  },
                }}
              />
            </Flex>

            <VStack spacing={2} align="stretch" width="full">
              <Box alignSelf="center" my={3}></Box>

              {steps.map((step, index) => (
                <Button
                  key={step.id}
                  onClick={() => handleSetStepIndex(index)} // Updated to use the new handler
                  variant={index === currentStepIndex ? 'solid' : 'ghost'}
                  justifyContent="flex-start"
                  leftIcon={<Circle size="10px" bg={index === currentStepIndex ? theme.colors.primary[600] : theme.colors.gray[300]} />}
                  sx={{
                    background: index === currentStepIndex ? 'var(--primary-light)' : 'initial',
                    color: index === currentStepIndex ? 'white' : 'initial',
                    _hover: {
                      bg: index === currentStepIndex ? 'var(--primary-medium)' : 'var(--hover-color-for-non-selected)',
                    },
                  }}
                >
                  {step.title}
                </Button>
              ))}

              <Flex width="full" mt={4} direction="column" alignItems="center">
                <Progress
                  value={completionPercentage}
                  width="90%"
                  borderRadius="md"
                  sx={{
                    '& > div': {
                      backgroundColor: 'var(--primary-light)',
                    },
                  }}
                />
                <Text mt={2} fontSize="sm">{`${Math.round(completionPercentage)}% Complete`}</Text>
              </Flex>
            </VStack>

            <Box flex="1" width="full" mt={6} p={4} overflowY="auto" bg="var(--primary-dark)" borderRadius="md">
              {React.createElement(currentStepComponent, { apiKey, onNextStep: handleNextStep, onPreviousStep: handlePreviousStep })}
            </Box>

          </MotionFlex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OnboardingGuide;
