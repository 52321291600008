import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import Cookies from 'js-cookie';
import { useDisclosure } from '@chakra-ui/react';
import { checkSubscription, getUserRole, getUsage } from '../services/appService';
import BillingUpgrade from '../components/upgrade/BillingUpgradeModal';

interface CreditContextType {
  checkCredits: () => Promise<void>;
  dismissModal: () => void;
}

interface CreditProviderProps {
  children: ReactNode;
}

const CreditContext = createContext<CreditContextType | undefined>(undefined);

export const CreditProvider: React.FC<CreditProviderProps> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [credits, setCredits] = useState<number>(0);
  const [remainingCredits, setRemainingCredits] = useState<number>(0);
  const [renewalDate, setRenewalDate] = useState<string>('');
  const [userRole, setUserRole] = useState<string>('');
  const [modalDismissed, setModalDismissed] = useState<boolean>(false);

  const checkCredits = async () => {
    if (modalDismissed) return;

    const accessToken = Cookies.get('AccessToken');
    if (!accessToken) return;
    try {
      const [subscription, userRoleResponse] = await Promise.all([
        checkSubscription(),
        getUserRole(accessToken),
      ]);

      const usageMetrics = await getUsage(accessToken);
      setUserRole(userRoleResponse.user_role);
      setRenewalDate(usageMetrics.cycle_end.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }));
      setCredits(userRoleResponse.credit_limit);
      setRemainingCredits(Math.max(userRoleResponse.credit_limit - usageMetrics.cycle_credits, 0));

      if (userRoleResponse.user_role === 'basic' && usageMetrics.cycle_credits >= 100) {
        Cookies.set('is_new_user', '0')
        onOpen();
      }
    } catch (error) {
      console.error('Error checking credits:', error);
    }
  };

  const dismissModal = () => {
    setModalDismissed(true);
    onClose();
  };

  useEffect(() => {
    checkCredits();
  }, []);

  return (
    <CreditContext.Provider value={{ checkCredits, dismissModal }}>
      {children}
      {isOpen && <BillingUpgrade isOpen={isOpen} onClose={dismissModal} renewalDate={renewalDate} remainingCredits={remainingCredits} />}
    </CreditContext.Provider>
  );
};

export const useCredit = (): CreditContextType => {
  const context = useContext(CreditContext);
  if (!context) {
    throw new Error('useCredit must be used within a CreditProvider');
  }
  return context;
};
