import React from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';

interface DashboardSectionProps {
  title: string;
  children: React.ReactNode;
}

const DashboardSection: React.FC<DashboardSectionProps> = ({ title, children }) => {
  return (
    <VStack align="stretch" spacing={4} width="100vx" mx="auto">
      <Text fontSize="3xl" fontWeight="bold" color="var(--tint-white)">{title}</Text>
      <Box overflowX="hidden"> {/* Optionally manage horizontal overflow for content within */}
        {children}
      </Box>
    </VStack>
  );
};

export default DashboardSection;
