import React from 'react';
import { Box, VStack, Link, Icon, Text, Button, useColorModeValue, HStack } from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FaDollarSign, FaHome, FaShareAlt } from 'react-icons/fa';
import { FaPython, FaReadme, FaRegLightbulb, FaGithub, FaXTwitter, FaLinkedin, FaTerminal } from 'react-icons/fa6';

import { GrPowerReset } from "react-icons/gr";
import Cookies from 'js-cookie';
import Logo from '../common/Logo';

const NavigationPane = () => {
  const location = useLocation();
  const activeLinkColor = useColorModeValue('var(--primary-light)', 'var(--primary-very-light)');

  const isActiveLink = (path: string): boolean => location.pathname === path;

  const logEventName = (eventName: string) => () => {
    window.dataLayer.push({ 
      event: `dashboard_resource_click_${eventName}`,
      user_id: Cookies.get('user_id'),
      category: 'User-Interaction',
      action: 'Clicked Button',
      label: eventName,
    });
  };

  const handleOnboardingClick = () => {
    Cookies.set('is_new_user', '1');
    logEventName('onboarding');
    window.location.reload();
  };

  return (
    <Box
      w="200px"
      h="full"
      p={5}
      boxShadow="0 10px 25px -5px rgba(0, 0, 0, 0.2), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"
      bg="var(--primary-dark)"
      color="var(--primary-very-light)"
      borderRadius="lg"
      sx={{
        '--primary-dark': '#0F172B',
        '--primary-light': '#6FA8FF',
        '--primary-very-light': '#B0CFFF',
      }}
      overflow="auto"
      display="flex"
      flexDirection="column"
    >
      <VStack align="stretch" spacing={4}>
        <Box alignSelf="center" my={0}>
          <Logo theme="light" />
        </Box>
        {[
          { to: "/", icon: FaHome, label: "Home" },
          { to: "/billing", icon: FaDollarSign, label: "Billing" },
          // Other navigation items...
        ].map((item) => (
          <Link
            key={item.to}
            as={RouterLink}
            to={item.to}
            display="flex"
            alignItems="center"
            color={isActiveLink(item.to) ? activeLinkColor : undefined}
            _hover={{ color: 'var(--primary-light)' }}
          >
            <Icon as={item.icon} w={5} h={5} mr={2} />
            <Text fontSize="lg">{item.label}</Text>
          </Link>
        ))}
        <Link
          href="https://docs.parcllabs.com/docs/parcl-labs-developer-quickstart-guide"
          isExternal
          display="flex"
          alignItems="center"
          _hover={{ color: 'var(--primary-light)' }}
          onClick={logEventName('quickstart_guide')}
        >
          <Icon as={FaReadme} w={5} h={5} mr={2} />
          <Text fontSize="lg">Quickstart</Text>
        </Link>
        <Link
          href="https://github.com/parcllabs/parcllabs-python"
          isExternal
          display="flex"
          alignItems="center"
          _hover={{ color: 'var(--primary-light)' }}
          onClick={logEventName('python_sdk')}
        >
          <Icon as={FaPython} w={5} h={5} mr={2} />
          <Text fontSize="lg">Python SDK</Text>
        </Link>
        <Link
          href="https://docs.parcllabs.com/reference"
          isExternal
          display="flex"
          alignItems="center"
          _hover={{ color: 'var(--primary-light)' }}
          onClick={logEventName('api_reference_docs')}
        >
          <Icon as={FaTerminal} w={5} h={5} mr={2} />
          <Text fontSize="lg">Documentation</Text>
        </Link>
        <Link
          href="https://github.com/ParclLabs/parcllabs-cookbook"
          isExternal
          display="flex"
          alignItems="center"
          _hover={{ color: 'var(--primary-light)' }}
          onClick={logEventName('cookbook')}
        >
          <Icon as={FaRegLightbulb} w={5} h={5} mr={2} />
          <Text fontSize="lg">Cookbook</Text>
        </Link>
        <Link
          href="#"
          display="flex"
          alignItems="center"
          _hover={{ color: 'var(--primary-light)' }}
          onClick={handleOnboardingClick}
        >
          <Icon as={GrPowerReset} w={5} h={5} mr={2} />
          <Text fontSize="lg">Onboarding</Text>
        </Link>
        <Link
          href="mailto:?subject=Parcl%20Labs%20API%3A%20Get%20Started&cc=team@parcllabs.com&body=Check%20out%20the%20Parcl%20Labs%20housing%20analytics%20API%21%0A%0AHere%E2%80%99s%20what%20you%20need%20to%20get%20started%3A%0A%0A-%20Sign%20up%20for%20free%20at%20https%3A%2F%2Fdashboard.parcllabs.com%2Fsignup%20and%20complete%20the%20in-app%20onboarding%20steps%20to%20make%20your%20first%20call.%0A-%20Explore%20the%20Parcl%20Labs%20docs%20site%20at%20https%3A%2F%2Fdocs.parcllabs.com%2Fdocs%2Fintroduction.%0A-%20The%20fastest%20way%20to%20start%20pulling%20and%20analyzing%20data%20is%20through%20the%20GitHub%20Use%20Case%20Repository%20at%20https%3A%2F%2Fgithub.com%2FParclLabs%2Fparcllabs-examples.%20The%20repo%20includes%20open-source%20notebooks%20for%20popular%20analyses%20like%20supply%20vs.%20demand%2C%20institutional%20investor%20concentration%2C%20rental%20trends%2C%20and%20more.%0A-%20Parcl%20Labs%20Python%20SDK%20available%20at%20https%3A%2F%2Fgithub.com%2FParclLabs%2Fparcllabs-python%20to%20enable%20common%20use%20cases%2C%20like%20pulling%20many%20markets%20at%20once.%0A%0AIf%20you%20have%20questions%2C%20you%20can%20post%20on%20the%20Parcl%20Labs%20community%20forum%20or%20email%20the%20team%20at%20team%40parcllabs.com."
          isExternal
          display="flex"
          alignItems="center"
          _hover={{ color: 'var(--primary-light)' }}
          onClick={logEventName('share_mail_to')}
        >
          <Icon as={FaShareAlt} w={5} h={5} mr={2} />
          <Text fontSize="lg">Share</Text>
        </Link>
      </VStack>
      {/* Support Button */}
      <Box mt="auto" p={2} display="flex" justifyContent="center">
        <Link
          href="https://docs.parcllabs.com/discuss"
          isExternal
          onClick={logEventName('support')}
        >
          <Button
            backgroundColor="var(--primary-light)"
            color="white"
            _hover={{ backgroundColor: "var(--primary-medium)" }}
          >
            Support
          </Button>
        </Link>
      </Box>
      {/* Icon links */}
      <HStack spacing={4} justify="center" mt={4}>
        <Link
          href="https://github.com/parcllabs"
          isExternal
          onClick={logEventName('social_github')}
        >
          <Icon as={FaGithub} w={6} h={6} />
        </Link>
        <Link
          href="https://twitter.com/ParclLabs"
          isExternal
          onClick={logEventName('social_twitter')}
        >
          <Icon as={FaXTwitter} w={6} h={6} />
        </Link>
        <Link
          href="https://www.linkedin.com/company/parcl-labs"
          isExternal
          onClick={logEventName('social_linked_in')}
        >
          <Icon as={FaLinkedin} w={6} h={6} />
        </Link>
      </HStack>
    </Box>
  );
};

export default NavigationPane;
