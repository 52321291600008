import React from 'react';
import { OnboardingStepProps } from './types';
import { StepLayout } from './StepLayout';
import { Image } from '@chakra-ui/react';
import LabsOnboardingOpener1  from '../../assets/images/LabsOnboardingOpener.svg';

const WelcomeStep: React.FC<OnboardingStepProps> = ({ onNextStep }) => {
  return (
    <StepLayout
      title="Welcome to Parcl Labs API!"
      description="You have successfully created your account. Let's get started by exploring what you can do with our API."
      onNextStep={onNextStep}
    >
      <Image
        src={LabsOnboardingOpener1}
        alt="Welcome to Parcl Labs API"
        mx="auto"
        objectFit="cover"
        width="100%"
        borderRadius="lg"
      />
    </StepLayout>
  );
};

export default WelcomeStep;
