import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, useToast, VStack, Text, Box, Icon, Link as ChakraLink, FormErrorMessage } from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import Logo from "../../common/Logo";
import { initiateResetPassword, confirmResetPassword } from '../../../services/cognitoService';
import { AuthFormWrapper}  from './AuthFormWrapper';
import { AuthFormField } from './AuthFormField';

const passwordCriteria = [
  { regex: /\d/, label: 'Contains at least 1 number' },
  { regex: /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/, label: 'Contains at least 1 special character' },
  { regex: /[A-Z]/, label: 'Contains at least 1 uppercase letter' },
  { regex: /[a-z]/, label: 'Contains at least 1 lowercase letter' },
];

interface ResetPasswordFormData {
  email: string;
  new_password: string;
  confirm_password: string;
  reset_code: string;
}


const ResetPasswordPage = () => {
  const { register, handleSubmit, watch, formState: { errors }, setError } = useForm<ResetPasswordFormData>();
  const toast = useToast();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const newPassword = watch('new_password');

  const validatePassword = (password: string) => {
    return passwordCriteria.every(criterion => criterion.regex.test(password));
  };

  const handleResetRequest = async (email: string) => {
    try {
      await initiateResetPassword(email);
      toast({
        title: 'Reset Code Sent',
        description: "Please check your email for the reset code.",
        status: 'success',
      });
      setStep(2); // Move to next step after successful code request
    } catch (error) {
      toast({
        title: "Reset Code Request Failed",
        description: error instanceof Error ? error.message : "An unexpected error occurred",
        status: "error",
      });
    }
  };

  const handlePasswordReset = async (data: ResetPasswordFormData) => {
    if (data.new_password !== data.confirm_password) {
      setError('confirm_password', { type: 'manual', message: "Passwords do not match." });
      return;
    }

    try {
      await confirmResetPassword(data.email, data.reset_code, data.new_password);
      toast({
        title: 'Password Reset Successfully',
        description: "You can now log in with your new password.",
        status: 'success',
      });
      navigate('/login'); // Redirect to login page
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
      setError('reset_code', { type: 'manual', message: errorMessage.includes("incorrect verification code") ? "Incorrect verification code. Please try again or request a new code." : errorMessage });
    }
  };

  const onSubmit = (data: ResetPasswordFormData) => {
    if (step === 1) {
      handleResetRequest(data.email);
    } else {
      handlePasswordReset(data);
    }
  };

  return (
    <AuthFormWrapper isLoading={false}>
      <VStack as="form" onSubmit={handleSubmit(onSubmit)} spacing={4} align="stretch">
        {step === 1 && (
          <>
            <AuthFormField<ResetPasswordFormData>
              id="email"
              label="Email"
              type="email"
              register={register}
              validationRule={{ required: 'Email is required' }}
              isInvalid={!!errors.email}
            />
            <Button mt={4} type="submit" colorScheme="blue">
              Send Reset Code
            </Button>
          </>
        )}
        {step === 2 && (
          <>
            <AuthFormField<ResetPasswordFormData>
              id="reset_code"
              label="Reset Code"
              type="text"
              register={register}
              validationRule={{ required: 'Reset code is required' }}
              isInvalid={!!errors.reset_code}
            />
            <FormErrorMessage>
              {errors.reset_code && errors.reset_code.message}
            </FormErrorMessage>

            <AuthFormField<ResetPasswordFormData>
              id="new_password"
              label="New Password"
              type="password"
              register={register}
              validationRule={{
                required: 'Password is required',
                validate: validatePassword
              }}
              isInvalid={!!errors.new_password}
            />
            <Box>
              {passwordCriteria.map((criterion, index) => (
                <Text key={index} fontSize="sm" color="var(--primary-very-light)">
                  <Icon as={criterion.regex.test(newPassword || '') ? CheckIcon : CloseIcon} color={criterion.regex.test(newPassword || '') ? 'green.500' : 'red.500'} />
                  {' '}{criterion.label}
                </Text>
              ))}
            </Box>

            <AuthFormField<ResetPasswordFormData>
              id="confirm_password"
              label="Confirm New Password"
              type="password"
              register={register}
              validationRule={{ required: 'Confirming new password is required' }}
              isInvalid={!!errors.confirm_password}
            />
            <FormErrorMessage>
              {errors.confirm_password && errors.confirm_password.message}
            </FormErrorMessage>

            <Button mt={4} type="submit" colorScheme="blue">
              Reset Password
            </Button>
            <Text textAlign="center" mt={2} fontSize="sm" color="var(--primary-very-light)">
              Didn't receive a code?{' '}
              <ChakraLink as={RouterLink} to="#" onClick={() => setStep(1)} style={{ textDecoration: 'underline' }}>
                Send a new code
              </ChakraLink>
            </Text>
          </>
        )}
        <Logo />
      </VStack>
    </AuthFormWrapper>
  );
};

export default ResetPasswordPage;
