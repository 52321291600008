import React, { useCallback } from 'react';
import AnalyticCard from './AnalyticCard';
import { AnalyticsCardProps } from './types';
import Container from '../Container';
import { useUsage } from '../../../../contexts/UsageContext';
import { formatRoleName } from '../../../../utils/formatUserRoleStrings';
import { useCurrentRole } from '../../../../contexts/currentRoleContext';
import { Text } from '@chakra-ui/react';
import { useCredit } from '../../../../contexts/creditContext';

const AnalyticUpdates: React.FC = () => {
  const { usageMetrics, refreshUsageMetrics } = useUsage();
  const { checkCredits } = useCredit();

  const handleRefresh = useCallback(async () => {
    await refreshUsageMetrics();
    checkCredits(); // Recalculate the upgrade prompt
  }, [refreshUsageMetrics, checkCredits]);

  const { currentRole, loadingRole } = useCurrentRole();

  if (loadingRole || !currentRole) {
    return <Text>Loading...</Text>;
  }

  const updates: AnalyticsCardProps[] = usageMetrics ? [
    {
      metricName: (
        <>
          Credits Used: <Text as="span" color="var(--primary-medium)">{formatRoleName(currentRole.user_role)} Tier</Text>
        </>
      ),
      interval: 'Cycle',
      value: usageMetrics.cycle_credits,
      startDate: usageMetrics.cycle_start.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
      endDate: usageMetrics.cycle_end.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
      onRefresh: handleRefresh,
    },
    {
      metricName: (
        <>
          Remaining Credits: <Text as="span" color="var(--primary-medium)">{formatRoleName(currentRole.user_role)} Tier</Text>
        </>
      ),
      interval: 'Cycle',
      value: Math.max(currentRole.credit_limit - usageMetrics.cycle_credits, 0),
      startDate: usageMetrics.cycle_start.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
      endDate: usageMetrics.cycle_end.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
      onRefresh: handleRefresh,
    },
  ] : []; // Return an empty array if usageMetrics is null

  return (
    <Container bgTransparent={true}>
      {updates.map((update, index) => (
        <AnalyticCard key={index} {...update} />
      ))}
    </Container>
  );
};

export default AnalyticUpdates;
