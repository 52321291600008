import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import CopyButton from './CopyButton';

interface CodeBoxProps {
  title: string;
  code: string;
}

const CodeBox: React.FC<CodeBoxProps> = ({ title, code }) => {
  SyntaxHighlighter.registerLanguage('bash', bash);

  return (
    <Box
      maxWidth="100%"
      overflowX="hidden"
      backgroundColor="black"
      borderRadius="lg"
      p={0}
      shadow="md"
    >
      <Box
        display="flex"
        justifyContent="start"
        alignItems="center"
        mb={1}
        backgroundColor="var(--primary-light)"
        color="white"
        pl={4} // Match this left padding with the SyntaxHighlighter's left margin/padding
        pr={4} // Optional, adjust right padding as needed
        pt={2}
        pb={2}
        borderTopRightRadius="lg"
        borderTopLeftRadius="lg"
      >
        <Text fontWeight="bold" flex="1" color="white">
          {title}
        </Text>
        <CopyButton text={code}/>
      </Box>
      <Box overflowX="auto" borderRadius="md">
        <SyntaxHighlighter
          language="bash"
          style={a11yDark}
          customStyle={{
            borderRadius: '0 0 lg lg',
            margin: '0',
            backgroundColor: 'black',
            textAlign: 'left',
            fontSize: 'smaller',
            padding: '16px', // Adjust this padding to match the header's padding, specifically the left padding
          }}
        >
          {code}
        </SyntaxHighlighter>
      </Box>
    </Box>
  );
};

export default CodeBox;
