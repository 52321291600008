import React, { useState } from 'react';
import {
  Box,
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Collapse,
  Icon,
  VStack,
  IconButton
} from '@chakra-ui/react';
import { FaKey, FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import CodeBox from '../../../common/CodeBox';
import { API_URL } from '../../../../config/apiConfig';
import CopyBoxApps from './CopyBoxApps';

interface MyAppsCardProps {
  appName: string;
  requestCount: number;
  apiKey: string;
}

const MyAppCard: React.FC<MyAppsCardProps> = ({ appName, requestCount, apiKey }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showCodeExamples, setShowCodeExamples] = useState(false);
  const borderRadius = "lg";
  const selectedParclId = '2900187';

  const curlCommands = [
    {
      title: 'Retrieve Gross Yield: NY Metro Area',
      command: `curl --request GET \\\n` +
        `  --location '${API_URL}/v1/rental_market_metrics/${selectedParclId}/gross_yield?limit=1' \\\n` +
        `  --header 'Authorization: ${apiKey}' \\\n` +
        `  --header 'accept: application/json'`
    },

    {
      title: 'Retrieve Investor Purchase to Sale Ratio: NY Metro Area',
      command: `curl --request GET \\\n` +
        `  --location '${API_URL}/v1/investor_metrics/${selectedParclId}/purchase_to_sale_ratio?limit=1' \\\n` +
        `  --header 'Authorization: ${apiKey}' \\\n` +
        `  --header 'accept: application/json'`
    }
  ];

  return (
    <Box
      borderWidth="1px"
      borderRadius={borderRadius}
      bg="white"
      borderColor="gray.200"
      width="100%"
      height="250px"
      position="relative"
      boxShadow="sm"
    >
      <Box bg="gray.100" paddingX="3" paddingY="1" width="100%" borderTopRadius={borderRadius}>
        <Text fontSize="lg" fontWeight="bold" color="gray.800">
          
          {appName}
        </Text>
      </Box>

      <Flex direction="column" justify="space-between" height="calc(100% - 48px)" paddingX="3">
        <Box paddingY="2">
          <Text fontSize="sm" color="gray.600">
            {requestCount} requests
          </Text>
        </Box>

        <Flex paddingY="0" align="center" justify="flex-start" paddingX='0'>
          <Button
            backgroundColor="var(--primary-light)" // Default background color
            color="white" // Adjust text color as needed
            leftIcon={<FaKey />} // Icon on the left
            onClick={onOpen}
            _hover={{
              backgroundColor: "var(--primary-medium)", // Background color on hover
            }}
          >
            API Key
          </Button>

        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent borderRadius={borderRadius}>

          <ModalBody padding="6">

            <Flex align="center" justify="space-between" width="full">
              <Text fontSize="2xl" fontWeight="bold" color="var(--primary-color)">
                Connect to Parcl Labs
              </Text>
              <IconButton
                aria-label="Close modal"
                size="sm"
                onClick={onClose}
                icon={<FaTimes />}
                variant="ghost"
                sx={{
                  color: 'var(--primary-dark)', // Sets the icon color
                  _hover: {
                    bg: 'var(--primary-light)', // Sets the background color on hover
                    borderRadius: '50%', // Makes the background circle
                    border: 'transparent', // Ensures there's no border
                  },
                }}
              />
            </Flex>





            <Box paddingY="2"></Box>
            <Text fontSize='sm' fontWeight="bold" mb="2">API Key:</Text>
            <CopyBoxApps text={apiKey} />

            <Flex justifyContent="space-between" alignItems="center" mt="6" mb="2">
              <Text fontSize='sm' fontWeight="bold" mb="2">Code Examples</Text>
              <Icon
                as={showCodeExamples ? FaChevronUp : FaChevronDown}
                w={6}
                h={6}
                onClick={() => setShowCodeExamples(!showCodeExamples)}
                cursor="pointer"
              />
            </Flex>

            <Collapse in={showCodeExamples}>
              <VStack spacing="4" mb="4">
                {curlCommands.map((item, index) => (
                  <CodeBox key={index} title={item.title} code={item.command} />
                ))}
              </VStack>
            </Collapse>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MyAppCard;
