// theme.tsx
import { extendTheme } from '@chakra-ui/react';

// Utility function to access CSS variables
const cssVar = (name: string) => `var(${name})`;

const theme = extendTheme({
  colors: {
    primary: {
      dark: cssVar('--primary-dark'),
      medium: cssVar('--primary-medium'),
      light: cssVar('--primary-light'),
      veryLight: cssVar('--primary-very-light'),
    },
    gradients: {
      header: cssVar('--gradient-header'),
      // Adding new gradient variables
      research: 'linear(to-br, var(--shade-dark4), var(--tint-light4))',
      press: 'linear(to-br, var(--shade-dark5), var(--tint-light5))',
      api: 'linear(to-br, var(--shade-dark6), var(--tint-light6))',
    },
    background: {
      dark: cssVar('--background-dark'),
    },
    boxShadow: {
      base: cssVar('--box-shadow'),
      light: cssVar('--box-shadow-light'),
      dark: cssVar('--box-shadow-dark'),
    },
    card: {
      light: cssVar('--card-background-light'),
      dark: cssVar('--card-background-dark'),
    },
    shades: {
      dark1: cssVar('--shade-dark1'),
      dark2: cssVar('--shade-dark2'),
      dark3: cssVar('--shade-dark3'),
      dark4: cssVar('--shade-dark4'),
      dark5: cssVar('--shade-dark5'),
      dark6: cssVar('--shade-dark6'),
    },
    tints: {
      light1: cssVar('--tint-light1'),
      light2: cssVar('--tint-light2'),
      light3: cssVar('--tint-light3'),
      light4: cssVar('--tint-light4'),
      light5: cssVar('--tint-light5'),
      light6: cssVar('--tint-light6'),
      light7: cssVar('--tint-light7'),
      white: cssVar('--tint-white'),
    },
  },
  // Extend other parts of the theme as needed
});

export default theme;
