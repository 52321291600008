// src/config/cognito-config.ts
const UserPoolId = process.env.REACT_APP_USER_POOL_ID;

const ClientId = process.env.REACT_APP_CLIENT_ID;

const cognitoConfig = {
    UserPoolId: UserPoolId,
    ClientId: ClientId,
};

export default cognitoConfig;