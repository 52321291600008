// CopyButton.tsx
import React from 'react';
import { Tooltip, IconButton, useClipboard } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

interface CopyButtonProps {
  text: string; // Add a text prop
}

const CopyButton: React.FC<CopyButtonProps> = ({ text }) => {
  const { hasCopied, onCopy } = useClipboard(text);
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    if (hasCopied) {
      setShowTooltip(true);
      const timer = setTimeout(() => {
        setShowTooltip(false);
      }, 1000); // Show the tooltip for 1 second
      return () => clearTimeout(timer); // Clean up the timeout
    }
  }, [hasCopied]);

  return (
    <Tooltip hasArrow label={hasCopied ? 'Copied' : 'Copy'} placement="top" isOpen={showTooltip}>
      <IconButton
        aria-label="copy code"
        icon={<CopyIcon w={3} h={3} color="white" />}
        onClick={onCopy}
        size="sm"
        variant="solid"
        backgroundColor="var(--primary-dark)"
        _hover={{
          background: "var(--primary-medium)",
          color: "white"
        }}
        border="none"
      />
    </Tooltip>
  );
};

export default CopyButton;
