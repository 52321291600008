import React, { useEffect, ReactNode } from 'react';
import TagManager from 'react-gtm-module';

interface GTMProviderProps {
  children: ReactNode;
}

const GTMProvider: React.FC<GTMProviderProps> = ({ children }) => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-PD6NVWG9'
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return <>{children}</>;
};

export default GTMProvider;
