import React from 'react';
import { Box, Flex, Center, Spinner } from '@chakra-ui/react';
import LabsBGImage from '../../../assets/images/LabsBGImage.png';

interface AuthFormWrapperProps {
  isLoading: boolean;
  children: React.ReactNode;
}

export const AuthFormWrapper: React.FC<AuthFormWrapperProps> = ({ isLoading, children }) => {
  return (
    <Flex
      align="center"
      justify="center"
      height="100vh"
      position="relative"
      overflow="hidden"
    >
      {/* Blurred Background */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="full"
        height="full"
        bgImage={LabsBGImage}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        filter="blur(4px)"
        zIndex="-1"
      />

      {/* Conditional Rendering Based on isLoading */}
      {isLoading ? (
        <Center height="full" width="full" position="absolute">
          <Spinner color="white" size="xl" thickness="4px" speed="0.65s" emptyColor="gray.200" />
        </Center>
      ) : (
        <Box
          px={8}
          py={6}
          mx="auto"
          width={["90%", "80%", "70%", "400px"]}
          maxWidth="500px"
          borderRadius="lg"
          boxShadow="var(--box-shadow)"
          bg="var(--primary-dark)"
          zIndex="10" // Ensure form content is above the blurred background
        >
          {children}
        </Box>
      )}
    </Flex>
  );
};
