// UsageContext.tsx
import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';
import { UsageResponse, getUsage } from '../services/appService';
import Cookies from 'js-cookie';

interface UsageContextType {
  usageMetrics: UsageResponse;
  refreshUsageMetrics: () => void;
}

const UsageContext = createContext<UsageContextType | undefined>(undefined);

interface UsageProviderProps {
  children: ReactNode;
}

export const UsageProvider: React.FC<UsageProviderProps> = ({ children }) => {
  // Initialize state with default values or loading state
  const [usageMetrics, setUsageMetrics] = useState<UsageResponse>({
    lifetime_credits: 0, 
    lifetime_api_requests: 0,
    cycle_credits: 0,
    cycle_api_requests: 0,
    cycle_count: 0,
    cycle_end: new Date(),
    cycle_start: new Date()
  });

  const fetchUsageMetrics = async () => {
    try {
      const accessToken = Cookies.get('AccessToken');
      if (!accessToken) {
        return;
      }
      // Fetch usage metrics from your API using the accessToken
      const fetchedMetrics: UsageResponse = await getUsage(accessToken);
      setUsageMetrics(fetchedMetrics);
    } catch (error) {
      console.error('Error fetching usage metrics:', error);
      // Handle error or set some error state if necessary
    }
  };

  // Fetch usage metrics when the component mounts
  useEffect(() => {
    fetchUsageMetrics();
  }, []); // Empty dependency array means this effect runs once on mount

  const refreshUsageMetrics = useCallback(() => {
    fetchUsageMetrics();
  }, []);

  return (
    <UsageContext.Provider value={{ usageMetrics, refreshUsageMetrics }}>
      {children}
    </UsageContext.Provider>
  );
};

// Custom hook to use the context
export const useUsage = () => {
  const context = useContext(UsageContext);
  if (context === undefined) {
    throw new Error('useUsage must be used within a UsageProvider');
  }
  return context;
};
