export const labelGradients = {
  RESEARCH: "linear(to-br, #448CF2, #2264C4)", // Lightens towards bottom right
  PRESS: "linear(to-br, #8FBDFF, #448CF2)", // Lightens towards bottom right
  API: "linear(to-br, #2264C4, #448CF2)", // Lightens towards bottom right
  // Add more label types and their associated gradients as needed
};


export interface UpdateCardProps {
    label: string;
    title: string;
    description: string;
    href: string;
    labelType: keyof typeof labelGradients; // Ensure labelType matches keys of labelGradients
    date: string;
  }



