import { createIcon } from '@chakra-ui/icons';


export const AtlantaPeachIcon = createIcon({
    displayName: "AtlantaPeachIcon",
    viewBox: "0 0 93 93",
    path: (
        <path
            d="M62.5412 15.0077C56.88 15.0077 51.5063 16.3197 46.7257 18.674C41.945 16.3197 36.5713 15.0077 30.9101 15.0077C29.1128 15.0077 27.3336 15.1335 25.5903 15.4031C22.6608 12.2939 20.8456 8.10634 20.8456 3.50544V0.629883H23.7212H29.4723C38.9976 0.629883 46.7257 8.35795 46.7257 17.8833C46.7257 8.35795 54.4537 0.629883 63.979 0.629883H69.7302H72.6057V3.50544C72.6057 8.10634 70.7905 12.2939 67.861 15.4031C66.1177 15.1335 64.3385 15.0077 62.5412 15.0077ZM30.9101 20.7588C36.6432 20.7588 41.999 22.3583 46.5639 25.1261L46.7257 25.2159C55.3523 30.5357 61.1035 40.061 61.1035 50.9342H66.8546C66.8546 39.2882 61.3191 28.9362 52.7284 22.3763C55.8016 21.316 59.1085 20.7408 62.5412 20.7408C79.2195 20.7408 92.7346 34.256 92.7346 50.9342C92.7346 78.2521 46.7257 92.6299 46.7257 92.6299C46.7257 92.6299 0.716675 78.2521 0.716675 50.9342C0.716675 34.256 14.2318 20.7408 30.9101 20.7408V20.7588Z"
            fill="currentColor"
        />
    ),
});


export const ChicagoDeepDishPizzaIcon = createIcon({
    displayName: "ChicagoDeepDishPizzaIcon",
    viewBox: "0 0 93 93",
    path: (
        <path
            d="M18.817 20.2193L0.866211 92.0583L72.7053 74.1076C72.7412 73.3888 72.7412 72.6701 72.7412 71.9333C72.7412 43.3451 49.5795 20.1833 20.9912 20.1833C20.2725 20.1833 19.5357 20.2013 18.817 20.2193ZM78.4912 72.6521L92.8662 69.0583C92.8662 30.9466 61.9779 0.0583496 23.8662 0.0583496L20.2725 14.4333C20.5061 14.4333 20.7576 14.4333 20.9912 14.4333C52.742 14.4333 78.4912 40.1826 78.4912 71.9333C78.4912 72.1669 78.4912 72.4185 78.4912 72.6521ZM32.4912 48.9333C29.3107 48.9333 26.7412 46.3638 26.7412 43.1833C26.7412 40.0029 29.3107 37.4333 32.4912 37.4333C35.6717 37.4333 38.2412 40.0029 38.2412 43.1833C38.2412 46.3638 35.6717 48.9333 32.4912 48.9333ZM55.4912 60.4333C55.4912 63.6138 52.9217 66.1833 49.7412 66.1833C46.5607 66.1833 43.9912 63.6138 43.9912 60.4333C43.9912 57.2529 46.5607 54.6833 49.7412 54.6833C52.9217 54.6833 55.4912 57.2529 55.4912 60.4333ZM29.6162 69.0583C29.6162 72.2388 27.0467 74.8083 23.8662 74.8083C20.6857 74.8083 18.1162 72.2388 18.1162 69.0583C18.1162 65.8779 20.6857 63.3083 23.8662 63.3083C27.0467 63.3083 29.6162 65.8779 29.6162 69.0583Z"
            fill="currentColor"
        />
    ),
});


export const LasVegasIcon = createIcon({
    displayName: "LasVegasIcon",
    viewBox: "0 0 93 93",
    path: (
        <path
            d="M1.83047 35.6498C-0.936768 30.8701 0.69842 24.7426 5.49617 21.9754L40.1406 1.97574C44.9204 -0.791504 51.0479 0.843684 53.8151 5.64144L83.8235 57.6261C86.5907 62.4058 84.9376 68.5333 80.1578 71.3005L45.4954 91.2822C40.7156 94.0494 34.5882 92.3963 31.8209 87.6165L1.83047 35.6498ZM35.8819 29.3067C33.8874 28.7677 31.8209 29.9536 31.2818 31.9662L29.9881 36.7999L26.3224 50.4744C25.0645 55.1463 27.8497 59.9441 32.5037 61.2019C37.1577 62.4598 41.9735 59.6745 43.2313 55.0205L43.6626 53.4393C43.6805 53.3674 43.6985 53.2955 43.7165 53.2416L46.897 58.7581L44.7227 60.016C43.5188 60.6988 43.1235 62.2441 43.8063 63.4301C44.4891 64.616 46.0345 65.0293 47.2204 64.3465L55.8815 59.3511C57.0855 58.6683 57.4808 57.1229 56.798 55.937C56.1151 54.751 54.5698 54.3377 53.3838 55.0205L51.2096 56.2784L48.029 50.7619C48.1009 50.7798 48.1728 50.7978 48.2267 50.8158L49.808 51.247C54.48 52.5049 59.2777 49.7197 60.5355 45.0657C61.7934 40.4117 59.0082 35.5959 54.3542 34.3381L40.6797 30.6724L35.8819 29.3067ZM83.0329 76.26C90.5619 71.9115 93.1495 62.2801 88.8009 54.733L64.2192 12.1642C64.5247 12.1283 64.8481 12.1283 65.1716 12.1283H105.422C110.975 12.1283 115.485 16.6385 115.485 22.191V82.5672C115.485 88.1196 110.975 92.6299 105.422 92.6299H65.1716C62.7098 92.6299 60.4637 91.7494 58.7207 90.2939L83.0329 76.26ZM96.4198 43.3227L105.063 34.3381C107.579 31.7326 107.435 27.42 104.668 25.0121C102.152 22.9097 98.4683 23.3051 96.2402 25.5871L95.3956 26.5215L94.4972 25.5871C92.251 23.3051 88.5314 22.8918 86.1056 25.0121C83.2844 27.438 83.1407 31.7505 85.6564 34.3381L94.3534 43.3047C94.8925 43.8797 95.8269 43.8797 96.4198 43.3047V43.3227Z"
            fill="currentColor"
        />
    ),
});

export const MiamiPalmTreeIcon = createIcon({
    displayName: "MiamiPalmTreeIcon",
    viewBox: "0 0 93 93",
    path: (
        <path
            d="M19.0117 17.0533L24.4562 26.1275C23.7375 26.7564 23.0188 27.4213 22.3 28.14C9.54219 40.8979 11.4828 55.2728 16.4242 62.5143C17.3227 63.826 19.1555 63.826 20.2695 62.6939L45.1562 37.8072C45.875 39.7478 46.5578 42.515 46.8992 46.2885C47.6539 54.6439 46.6117 67.276 41.0234 85.1729C40.4844 86.9158 40.7898 88.8205 41.8859 90.2939C42.982 91.7674 44.6891 92.6299 46.5219 92.6299H58.0219C60.9148 92.6299 63.3766 90.4736 63.7359 87.5986C65.9641 69.8096 63.3227 50.3674 58.0398 35.1299H77.7883C78.2914 35.1299 78.7586 34.8604 79.0281 34.4291L83.55 28.5533C84.107 27.6189 85.4547 27.6189 86.0117 28.5533L90.5336 34.4291C90.7852 34.8604 91.2703 35.1299 91.7734 35.1299H101.147C102.728 35.1299 104.04 33.8361 103.734 32.2729C102.099 23.6658 93.3125 12.1299 75.2719 12.1299C67.4195 12.1299 61.3281 14.3221 56.8 17.4846C53.7992 9.50645 45.0844 0.629883 29.2719 0.629883C11.2313 0.629883 2.44453 12.1658 0.809375 20.7729C0.503907 22.3361 1.81563 23.6299 3.39688 23.6299H11.7883C12.2914 23.6299 12.7586 23.3604 13.0281 22.9291L16.532 17.0533C17.0891 16.1189 18.4367 16.1189 18.9938 17.0533H19.0117Z"
            fill="currentColor"
        />
    ),
});


