import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, useToast, VStack, Text, Box, Icon, Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import Logo from "../../common/Logo";
import { AuthFormWrapper } from './AuthFormWrapper';
import { AuthFormField } from './AuthFormField';
import { signUp, verifyAccount } from '../../../services/cognitoService';
interface RegisterFormData {
  email: string;
  password: string;
  verificationCode?: string;
}

const passwordCriteria = [
  { regex: /\d/, label: 'Contains at least 1 number' },
  { regex: /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/, label: 'Contains at least 1 special character' },
  { regex: /[A-Z]/, label: 'Contains at least 1 uppercase letter' },
  { regex: /[a-z]/, label: 'Contains at least 1 lowercase letter' },
];

export const SignupForm = () => {
  window.dataLayer.push({
    event: 'signup_page_view',
    page: {
      url: window.location.href,
      title: document.title,
    }
  })
  const toast = useToast();
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm<RegisterFormData>();
  const [isVerificationStep, setIsVerificationStep] = useState(false);
  const passwordValue = watch('password');

  const validatePassword = (password: string) => {
    return passwordCriteria.every(criterion => criterion.regex.test(password));
  };

  const onSubmit = async (data: RegisterFormData) => {

    if (!isVerificationStep) {
      try {
        await signUp(data.email, data.password);
        toast({
          title: "Registration successful",
          description: "Please check your email for the verification code.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'signup',
          category: 'User-Signup',
          action: 'Clicked Button',
          label: 'Clicked Registration Button - Waiting Verification Code',
        });


        setIsVerificationStep(true);
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
        toast({
          title: "Registration failed",
          description: errorMessage,
          status: "error",
          duration: 900,
          isClosable: true,
        });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'signup_error',
          category: 'User-Signup',
          action: 'Registration Error',
          label: 'Registration failed with error: ' + errorMessage,
        });
      }
    } else {
      try {
        await verifyAccount(data.email, data.verificationCode!);
        toast({
          title: "Account verified",
          description: "Your account has been successfully verified. You are now being logged in.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'sign_up',
          category: 'User-Signup',
          action: 'Clicked Button',
          label: 'Clicked Verification Button - Account Verified',
        });
        navigate('/login');
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "Verification failed";
        toast({
          title: "Verification failed",
          description: errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'verification_error',
          category: 'User-Signup',
          action: 'Verification Error',
          label: 'Verification failed with error: ' + errorMessage,
        });
      }
    }
  };

  return (

    <AuthFormWrapper isLoading={false}>
      <VStack as="form" onSubmit={handleSubmit(onSubmit)} spacing={4} align="stretch">
        {!isVerificationStep && (
          <>
            <AuthFormField<RegisterFormData>
              id="email"
              label="Email"
              type="email"
              register={register}
              validationRule={{ required: 'Email is required' }}
              isInvalid={!!errors.email}
            />
            <AuthFormField<RegisterFormData>
              id="password"
              label="Password"
              type="password"
              register={register}
              validationRule={{
                required: "Password is required",
                validate: validatePassword
              }}
              isInvalid={!!errors.password}
            />
            <Box>
              {passwordCriteria.map((criterion, index) => (
                <Text key={index} fontSize="sm" color="var(--primary-very-light)">
                  <Icon as={criterion.regex.test(passwordValue || '') ? CheckIcon : CloseIcon} color={criterion.regex.test(passwordValue || '') ? 'green.500' : 'red.500'} />
                  {' '}{criterion.label}
                </Text>
              ))}
            </Box>
          </>
        )}
        {isVerificationStep && (
          <AuthFormField<RegisterFormData>
            id="verificationCode"
            label="Verification Code"
            type="text"
            register={register}
            validationRule={{ required: 'Verification code is required' }}
            isInvalid={!!errors.verificationCode}
          />
        )}
        <Button mt={4} type="submit" colorScheme="blue">
          {isVerificationStep ? 'Verify Account' : 'Sign Up'}
        </Button>
        <Text textAlign="center" mt={2} fontSize="sm" color="var(--primary-very-light)">
          {isVerificationStep ? (
            <>
              Didn't receive a code?{' '}
              <ChakraLink
                as={RouterLink}
                to="#"
                onClick={() => setIsVerificationStep(false)}
                style={{ textDecoration: 'underline' }}
              >
                Send a new code
              </ChakraLink>
            </>
          ) : (
            <>
              By registering, you agree to our{' '}
              <ChakraLink
                href="https://www.parcllabs.com/legal/terms-of-use"
                isExternal
              >
                Terms of Use
              </ChakraLink>
              {' '}and{' '}
              <ChakraLink
                href="https://www.parcllabs.com/legal/api-license"
                isExternal
              >
                API License Agreement
              </ChakraLink>
              .
            </>
          )}
        </Text>
        {!isVerificationStep && (
          <Text textAlign="center" mt={0} fontSize="sm" color="var(--primary-very-light)">
            Already have an account?{' '}
            <ChakraLink
              as={RouterLink}
              to="/login"
            >
              Log in
            </ChakraLink>
          </Text>
        )}
        <Logo />
      </VStack>
    </AuthFormWrapper>
  );
};