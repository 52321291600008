// apiConfig.tsx
export const API_URL = process.env.REACT_APP_API_URL;

const APP_SERVICE_URL = process.env.REACT_APP_UTILS_URL;

export const APP_BACKEND_ENDPOINTS = {
    GET_API_KEY: `${APP_SERVICE_URL}/user/tokens`,
    CREATE_API_USER: `${APP_SERVICE_URL}/user/create`,
    GET_USER_ROLE: `${APP_SERVICE_URL}/user/role`,
    GET_ALL_ROLES: `${APP_SERVICE_URL}/role/all`,
    LIFETIME_USAGE: `${APP_SERVICE_URL}/usage/lifetime`,
    CURRENT_USAGE: `${APP_SERVICE_URL}/usage/current`,
    USAGE: `${APP_SERVICE_URL}/usage`,
    SHIPPED: `${APP_SERVICE_URL}/shipped`,
    CREATE_CHECKOUT_SESSION: `${APP_SERVICE_URL}/billing/checkout`,
    CREATE_PORTAL_SESSION: `${APP_SERVICE_URL}/billing/portal`,
    CHECK_SUBSCRIPTION: `${APP_SERVICE_URL}/billing/subscribed`,
}
