import React from 'react';
import { Text } from '@chakra-ui/react';
import { OnboardingStepProps } from './types';
import { StepLayout } from './StepLayout'; // Adjust the import path as necessary
import CopyBox from '../common/CopyBox';

const ViewApiKeyStep: React.FC<OnboardingStepProps> = ({ onNextStep, apiKey }) => {
  // Assuming `apiKey` prop is the actual API key to be displayed and copied.
  // If not, replace `apiKey` with your actual API key variable.
  return (
    <StepLayout
      title="View your API Key"
      description="Your API Key is your connection to the Parcl Labs data universe. Use it to read Parcl Labs data."
      onNextStep={onNextStep}
    >
      <Text fontSize="small" color="white" fontWeight="bold" alignSelf="start" mt="4">API Key</Text>
      {/* Render the CopyBox component with the API key */}
      <CopyBox text={apiKey!} />
    </StepLayout>
  );
};

export default ViewApiKeyStep;
