import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

interface ContainerProps {
  children: React.ReactNode;
  header?: string; // Optional header text
  bgTransparent?: boolean; // Optional flag for transparent background
}

const Container: React.FC<ContainerProps> = ({ children, header, bgTransparent }) => {
  return (
    <Box
      bg={bgTransparent ? 'transparent' : 'gray.100'}
      w="100%"
      maxW="100vw"
      px="0" // Added padding for overall container spacing
      py="4"
      mx="auto"
      borderRadius="lg"
      overflowX="hidden"
      overflowY="hidden"
    >
      {header && (
        <Text
          fontSize="xl"
          fontWeight="bold"
          p="4"
          bg={bgTransparent ? 'transparent' : 'gray.200'}
          color="black"
          borderBottomWidth="1px"
          borderColor="gray.300"
        >
          {header}
        </Text>
      )}
      <Flex
        wrap="wrap" // Allow the cards to wrap
        justifyContent="flex-start" // Align items to the start
        gap="20px" // Consistent gap between items
      >
        {React.Children.map(children, (child, index) => (
          <Box
            flex="1" // Allow boxes to grow and fill available space
            minWidth="300px" // Minimum width before wrapping
            height="250px"
          >
            {child}
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default Container;
