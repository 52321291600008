import React, { useState } from 'react';
import { Button, Box, Text, VStack, HStack, Icon } from '@chakra-ui/react';
import { ChicagoDeepDishPizzaIcon, MiamiPalmTreeIcon, LasVegasIcon, AtlantaPeachIcon } from '../../assets/icons/onboardingIcons';
import { OnboardingStepProps } from './types';
import { StepLayout } from './StepLayout'; // Ensure StepLayout is imported correctly
import CodeBox from '../common/CodeBox';
import { API_URL } from '../../config/apiConfig';

const cityData = {
  Atlanta: { parcl_id: 2887280, icon: AtlantaPeachIcon },
  Chicago: { parcl_id: 2899845, icon: ChicagoDeepDishPizzaIcon },
  "Las Vegas": { parcl_id: 2900049, icon: LasVegasIcon },
  Miami: { parcl_id: 2900128, icon: MiamiPalmTreeIcon },
};

const SendFirstRequestStep: React.FC<OnboardingStepProps> = ({ onNextStep, apiKey }) => {
  const [selectedParclId, setSelectedParclId] = useState(cityData.Atlanta.parcl_id);
  const exampleRequest = `curl --request GET \\\n` +
    `  --location '${API_URL}/v1/investor_metrics/${selectedParclId}/purchase_to_sale_ratio?limit=1' \\\n` +
    `  --header 'Authorization: ${apiKey}' \\\n` + // Ensure to use 'Bearer' if needed
    `  --header 'accept: application/json'`;

  return (
    <StepLayout
      title="Send your first request"
      description="Select a market, copy the request, and paste into your terminal to send your first request to the Parcl Labs API."
      onNextStep={onNextStep}
    >
      {/* Market Selection */}
      <HStack spacing={4} overflowX="auto">
        {Object.entries(cityData).map(([city, { parcl_id, icon }]) => (
          <Button
            key={parcl_id}
            onClick={() => setSelectedParclId(parcl_id)}
            variant="ghost"
            p={0}
            _hover={{
              background: 'none', // Ensures no background change on hover
              textDecoration: 'none', // Removes any text decoration that might be applied
              transform: 'none' // Ensures no transformation on hover
            }}
            _active={{
              bg: 'none' // Optional: Ensures the button doesn't change on active/focus either
            }}
            _focus={{
              boxShadow: 'none' // Removes focus outline or shadow
            }}
          >
            <VStack spacing={1}>
              <Icon
                as={icon}
                boxSize={4}
                color={selectedParclId === parcl_id ? 'primary.light' : 'white'}
              />
              <Text color="white" fontSize="xs">{city}</Text>
            </VStack>
          </Button>

        ))}
      </HStack>

      <Box maxWidth="100%" overflowX="auto"> {/* Control overflow and width */}
        <CodeBox title="Copy Example Request Below" code={exampleRequest} />
      </Box>
    </StepLayout>
  );
};

export default SendFirstRequestStep;
