import React from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import {signOut} from '../services/cognitoService';
import { Button } from '@chakra-ui/react';


const LogoutButton: React.FC = () => {
const navigate = useNavigate();
  const handleLogout = () => {
    // Get all cookies
    const allCookies = Cookies.get();
    
    // Sign out of Cognito
    signOut(Cookies.get('AccessToken')!);
    // Iterate over all cookies and remove them
    Object.keys(allCookies).forEach(cookieName => Cookies.remove(cookieName));

    // Add any additional logout logic here
    // For example, redirecting the user or updating the application state
    // Redirect to home/login page or update state
    navigate('/login');
  };

  return (
    <Button variant="ghost" onClick={handleLogout}>
            Logout
        </Button>
  );
};

export default LogoutButton;
