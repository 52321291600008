// src/services/cognitoService.ts
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import cognitoConfig from '../config/cognito-config';
import {jwtDecode} from 'jwt-decode';


if (!cognitoConfig.UserPoolId || !cognitoConfig.ClientId) {
  throw new Error(`Cognito configuration is incomplete. UserPoolId: ${cognitoConfig.UserPoolId || 'undefined'}, ClientId: ${cognitoConfig.ClientId || 'undefined'}. Please check your environment variables.`);
};


const userPool = new CognitoUserPool({
    UserPoolId: cognitoConfig.UserPoolId,
    ClientId: cognitoConfig.ClientId,
});

interface CodeDeliveryDetails {
    AttributeName: string;
    DeliveryMedium: string;
    Destination: string;
  };
  
  interface SignUpResult {
    CodeDeliveryDetails: CodeDeliveryDetails;
    UserConfirmed: boolean;
    UserSub: string;
  };
  

  export const signUp = (email: string, password: string): Promise<SignUpResult> => {
    return new Promise((resolve, reject) => {
      const emailAttribute = new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      });
  
      userPool.signUp(email, password, [emailAttribute], [], (err, result) => {
        if (err) {
          reject(err);
        } else if (!result) {
          reject(new Error('SignUp result is undefined.'));
        } else {
          // Map the result to match SignUpResult interface
          const signUpResult: SignUpResult = {
            CodeDeliveryDetails: {
              AttributeName: result.codeDeliveryDetails?.AttributeName || '',
              DeliveryMedium: result.codeDeliveryDetails?.DeliveryMedium || '',
              Destination: result.codeDeliveryDetails?.Destination || '',
            },
            UserConfirmed: result.userConfirmed,
            UserSub: result.userSub,
          };
          resolve(signUpResult);
        }
      });
    });
  };


export const signIn = (email: string, password: string): Promise<{ idToken: { jwtToken: string }; accessToken: { jwtToken: string }; refreshToken: { jwtToken: string }; }> => {
    return new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });
        const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                resolve({
                    idToken: { jwtToken: result.getIdToken().getJwtToken() },
                    accessToken: { jwtToken: result.getAccessToken().getJwtToken() },
                    refreshToken: { jwtToken: result.getRefreshToken().getToken() }
                });
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
};

// Add a function to verify the account with the code
export const verifyAccount = (email: string, code: string) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        cognitoUser.confirmRegistration(code, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result); // Typically 'SUCCESS'
            }
        });
    });
};

export const initiateResetPassword = (email: string) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        cognitoUser.forgotPassword({
            onSuccess: (data) => {
                // onSuccess is called when the verification code is successfully sent
                resolve(data);
            },
            onFailure: (err) => {
                // onFailure is called when there's an error in sending the code
                reject(err);
            },
            // Optional: if inputVerificationCode is defined, it will be called
            // before onSuccess, providing an opportunity to prompt the user to enter the verification code
        });
    });
};

export const confirmResetPassword = (email: string, code: string, newPassword: string) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: userPool,
        });

        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess: () => {
                // onSuccess is called when the password is successfully updated
                resolve("Password updated successfully.");
            },
            onFailure: (err) => {
                // onFailure is called if there's an error during the password update process
                reject(err);
            }
        });
    });
};


/**
 * Refreshes the user's tokens using the stored refresh token.
 * @param {string} email - The user's email address.
 * @param {string} refreshToken - The refresh token.
 * @returns {Promise} A promise that resolves with the new access and ID tokens.
 */
export const refreshTokens = (email: string, refreshToken: string): Promise<{ accessToken: string; idToken: string }> => {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: email,
        Pool: userPool,
      };
  
      const cognitoUser = new CognitoUser(userData);
      const refreshSessionToken = new CognitoRefreshToken({RefreshToken: refreshToken});
  
      cognitoUser.refreshSession(refreshSessionToken, (err, session) => {
        if (err) {
          reject(err);
          return;
        }
  
        // Assuming you want to resolve with both the new access and ID tokens
        resolve({
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken(),
        });
      });
    });
  };


  interface DecodedToken {
    sub: string;
  }
  
  export const signOut = (accessToken: string): void => {
    // Decode the JWT to get the sub
    const decoded: DecodedToken = jwtDecode(accessToken);
  
    // Assuming sub is used as username in your Cognito User Pool configuration
    const username = decoded.sub; // Directly using sub as username
  
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });
  
    cognitoUser.signOut(); // This method is synchronous and does not return a promise
  };