import React from 'react';
// Import the Image component from Chakra UI
import { Image } from '@chakra-ui/react';
// Import locally stored images
import pfp_primary from '../../assets/images/pfp_primary.png';
import parcllabs_logo_white_250x45 from '../../assets/images/parcllabs_logo_white_250x45.png';

interface LogoProps {
  theme?: 'light' | 'dark';
  maxW?: string | number; // Optional maximum width
  maxH?: string | number; // Optional maximum height
}

const Logo: React.FC<LogoProps> = ({ theme = 'light', maxW = '100px', maxH = 'auto' }) => {
  // Use imported images based on the theme
  const logoSrc = theme === 'light' ? parcllabs_logo_white_250x45 : pfp_primary;

  return (
    <Image
      src={logoSrc}
      alt={`Powered by Parcl Labs - ${theme} theme`}
      mx="auto"
      maxW={maxW}
      h={maxH}
      className={`logo logo-${theme}`}
      onError={(e) => {
        e.currentTarget.style.display = 'none'; // Hide the image on error
      }}
    />
  );
}

export default Logo;
