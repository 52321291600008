// authUtils.ts
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode';


interface DecodedToken {
  exp?: number; // Marking it as optional to account for its potential absence
}

export async function checkAccessTokenExpiration(): Promise<boolean> {
  const AccessToken = Cookies.get('AccessToken'); // Assuming IDToken stores the JWT

  if (!AccessToken) {
    logout(); // No ID token available, log out
    return false;
  }

  try {
    const decodedToken = jwtDecode<DecodedToken>(AccessToken); // Type assertion here

    // Check if 'exp' is defined to satisfy TypeScript's strict null checks
    if (decodedToken.exp === undefined) {
      console.error('Token expiration time is undefined.');
      logout();
      return false;
    }

    const currentTime = Math.floor(Date.now() / 1000);

    if (decodedToken.exp <= currentTime) {
      console.log('Access token has expired. Logging out.');
      logout();
      return false;
    }

    console.log('Access token is still valid.');
    return true;
  } catch (error) {
    console.error('Error checking token expiration:', error);
    logout();
    return false;
  }
}

export function logout() {
  Cookies.remove('AccessToken');
  Cookies.remove('RefreshToken');
  Cookies.remove('TokenExpiration');
  Cookies.remove('IdToken');
  window.location.href = '/login';
}
