import React, { ReactNode, ReactElement, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SignupForm } from './components/signin/Web2/SignupForm';
import { LoginPage } from './components/signin/Web2/LoginPage';
import ResetPasswordPage from './components/signin/Web2/ResetPasswordPage';
import DashboardLayout from './components/dashboard/DashboardLayout';
import HomeView from './components/dashboard/Home/HomeView';
import Profile from './components/dashboard/Profile';
import BillingComponent from './components/dashboard/Billing';
import { checkAccessTokenExpiration } from './utils/authUtils';
import theme from './theme';
import AOS from 'aos';
import 'aos/dist/aos.css'; // AOS styles
import { UsageProvider } from './contexts/UsageContext';
import { RolesProvider } from './contexts/allRolesContext';
import { CurrentRoleProvider } from './contexts/currentRoleContext';
import { CreditProvider } from './contexts/creditContext';
import GTMProvider from './GTMProvider';

AOS.init();

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }): ReactElement | null => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      // Check if the app is running in development mode and if bypassing auth is enabled
      if (process.env.REACT_APP_BYPASS_AUTH === 'true') {
        setIsAuthorized(true);
      } else {
        const hasValidToken = await checkAccessTokenExpiration();
        setIsAuthorized(hasValidToken);
      }
      setIsCheckingAuth(false);
    };

    checkAuth();
  }, []);

  if (isCheckingAuth) {
    return <div>Loading...</div>;
  }

  return isAuthorized ? (
    <UsageProvider>
      <RolesProvider>
        <CurrentRoleProvider>
          <CreditProvider>
            {children}
          </CreditProvider>
        </CurrentRoleProvider>
      </RolesProvider>
    </UsageProvider>
  ) : (
    <Navigate to="/login" />
  );
};

const App: React.FC = () => {
  return (
    <GTMProvider>
      <ChakraProvider theme={theme}>
          <RolesProvider>
            <CurrentRoleProvider>
              <UsageProvider>
                <Router>
                  <Routes>
                    <Route path="/" element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>}>
                      <Route index element={<HomeView />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/billing" element={<BillingComponent />} />
                      <Route path="/subscribe" element={<BillingComponent />} />
                      <Route path="/success" element={<BillingComponent />} />
                    </Route>
                    <Route path="/signup" element={<SignupForm />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/reset-password" element={<ResetPasswordPage />} />
                    {/* Define other routes as needed */}
                    {/* Default route for unmatched paths */}
                    <Route path="*" element={<Navigate to="/login" replace />} />
                  </Routes>
                </Router>
              </UsageProvider>
            </CurrentRoleProvider>
          </RolesProvider>
      </ChakraProvider>
    </GTMProvider>
  );
};

export default App;